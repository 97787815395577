import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Table, Spinner, Image } from 'react-bootstrap';
import { loadOrders, selectOrders, selectOrdersPagesLoaded, selectOrdersTotalPages } from '../../../features/orders/ordersSlice';
import { selectToken } from '../../../features/user/userSlice';
import { selectIsMobileRoute, setIsLoadMore } from '../../../features/mobile/mobileSlice';
import Loader from '../../../components/Loader';
import OrderObj from '../../../features/orders/orderObj';
import { useEffect, useMemo, useState } from 'react';
import { selectCustomer } from '../../../features/customer/customerSlice';
import { setNotifications, setPreviousDate, setSelectedDate, setSelectedZipCode, setTurnOnAutoship } from '../core/autoShipSlice';
import CustomerObj from '../../../features/customer/customerObj';
import AutoShipAPI from '../core/autoshipAPI';

export default function AutoshipOrdersListPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const custData = useSelector(selectCustomer);
  const customer = useMemo(() => {
    return new CustomerObj(custData);
  }, [custData]);
  const pages_loaded: number = useSelector(selectOrdersPagesLoaded);
  const orders: Array<Record<string, any>> = useSelector(selectOrders);
  const autoshipOrders = orders.filter((order, index, self) => (order.status === "autoship" ||
    order.status === "mmfailpayment") && index === self.findIndex((o) => o.id === order.id));
  const total_pages: number = useSelector(selectOrdersTotalPages);
  const [isLoading, setIsLoading] = useState(false);
  const [turningOn, setTurningON] = useState(false);
  const order = OrderObj.findRecentOrder(orders);
  const zip = custData?.shipping?.postcode ? custData?.shipping?.postcode : custData?.billing?.postcode;
  const orderDate = order?.getDeliveryDateTime();
  const OrderPlannerImage = 'https://eatmightymeals.com/wp-content/uploads/2024/07/order-planner-banner-mobile.jpg';
  const zipCode = order?.data.shipping.postcode ? order?.data.shipping.postcode :
    order?.data.billing.postcode ? order?.data.billing.postcode : zip;
  const ClickFromNav = location && location.state && location.state.clickFromNav === true;
  const processingOrders = orders.filter((order: any) => {
    const orderObj = new OrderObj(order);
    return orderObj.data.status === 'autoship';
  });
  const lastOrder = autoshipOrders.at(-1);
  const latestOrder = lastOrder && new OrderObj(lastOrder);
  const lastOrderedDate = latestOrder && latestOrder.getDeliveryDateTime();

  useEffect(() => {
    if (autoshipOrders.length > 0 && ClickFromNav) {
      navigate(`/autoship${urlParams}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ClickFromNav, autoshipOrders, navigate])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        dispatch(setSelectedDate(null));
        if (token && customer.data.id) {
          await dispatch(loadOrders({ token, isAutoship: true, customerId: customer.data.id }));
          await dispatch(setIsLoadMore(false));
        }
      } catch (e: any) {
        console.log("Error while listing Orders: ", e);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, customer.data.id]);

  const handleTurnOnAutoship = async () => {
    setTurningON(true);
    try {
      await AutoShipAPI.postUserNotifications({
        userId: custData.id,
        autoshipAutoAddWeeks: '1',
        autoshipEmailNotifications: '1',
        autoshipTextMsgNotifications: '1',
      }).then((response) => {
        if (response.status === "success") {
          dispatch(setNotifications(response.data));
        }
      });
    } catch (e: any) {
      console.log(e, "Error while Turning on Autoship");
    } finally {
      dispatch(setTurnOnAutoship(true));
      dispatch(setPreviousDate(orderDate));
      dispatch(setSelectedDate(orderDate));
      dispatch(setSelectedZipCode(zipCode));
      navigate(`/autoship${urlParams}`);
      setTurningON(false);
    }
  }

  const handleAutoshipNoOrder = () => {
    if (processingOrders.length > 0) {
      dispatch(setPreviousDate(lastOrderedDate));
      dispatch(setSelectedDate(lastOrderedDate));
      dispatch(setSelectedZipCode(zip));
      navigate(`/autoship${urlParams}`);
    } else {
      handleTurnOnAutoship();
    }
  }

  return (
    <Card body className='my-orders my-5 flex-fill'>
      {(!pages_loaded || isLoading) ?
        <div className='text-center my-5 h-100'>
          <Loader />
        </div> :
        <>
          {autoshipOrders.length === 0 ? (
            <div className='autoShip'>
              {turningOn ?
                <div className='text-center my-5 h-100'>
                  <Loader />
                </div> :
                <Image
                  src={OrderPlannerImage}
                  className='cursor-pointer'
                  alt='orderPlanner'
                  onClick={handleTurnOnAutoship} />}
            </div>
          ) : (
            <>
              <div className='d-flex justify-content-center align-items-center'>
                <Button
                  onClick={handleAutoshipNoOrder}
                  size="sm"
                  variant="success"
                  className='mx-3 w-50 text-white mb-3'
                >
                  View Order Planner Calendar
                </Button>
              </div>
              <Table
                striped
                responsive
                className='table-borderless table-sm'
              >
                <thead>
                  <tr>
                    <th>Order</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>Delivery Date</th>
                    <th>Total</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {autoshipOrders.sort((a, b) => {
                    const orderA = new OrderObj(a);
                    const orderB = new OrderObj(b);
                    const dateA = new Date(orderA.getDeliveryDate()).getTime();
                    const dateB = new Date(orderB.getDeliveryDate()).getTime();
                    if (isNaN(dateA)) return 1;
                    if (isNaN(dateB)) return -1;
                    return dateA - dateB;
                  }).map((orderData, index: number) => {
                    const order = new OrderObj(orderData);
                    const OrderStatus = order.data.status === 'autoship' ? 'Pending' :
                      order.data.status === 'mmfailpayment' ? 'Failed' : order.data.status;

                    return (
                      <tr key={index} className='position-relative'>
                        <td>
                          <Link to={'/autoship/orders/' + order.data.id + urlParams} className='text-black'>
                            #{order.data.id}
                          </Link>
                        </td>
                        <td>{order.getDate()}</td>
                        <td>{OrderStatus}</td>
                        <td>{order.getDeliveryDate()}</td>
                        <td>
                          <span className='fw-bold'>${order.data.total}</span><br />
                          for {order.getTotalItems()} items
                        </td>
                        <td>
                          <div className='vstack gap-1'>
                            <Link to={'/autoship/orders/' + order.data.id + urlParams}>
                              <Button
                                size="sm"
                                variant="success"
                                className='text-white mt-0 my-2 w-100'
                              >
                                View
                              </Button>
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className='d-flex justify-content-center align-items-center'>
                {pages_loaded < total_pages &&
                  <Button
                    onClick={() => {
                      setIsLoading(true);
                      dispatch(setIsLoadMore(true));
                    }}
                    disabled={isLoading}
                    variant='success'
                    className='text-white'
                  >
                    {isLoading ?
                      <>
                        Loading ... &nbsp;
                        <Spinner
                          animation="border"
                          as="span"
                          size="sm"
                        />
                      </>
                      : 'Load more'}
                  </Button>
                }
              </div>
            </>)}
        </>}
    </Card>
  );
}