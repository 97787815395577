import { useState } from "react";
import { Button, Modal } from 'react-bootstrap';
import ProductObj from "../products/productObj";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faRegularHeart } from '@fortawesome/free-regular-svg-icons';
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";
import MealPlanForm from "./MealPlanForm"
import { Link, useNavigate } from "react-router-dom";
import { selectToken } from "../user/userSlice";
import { useSelector } from "react-redux";
import { selectMealPlans, selectMealPlansLoaded } from "./mealPlansSlice";
import { selectIsMobileRoute } from "../mobile/mobileSlice";
interface Props {
  product: ProductObj;
}

export default function AddToMealPlan({ product }: Props) {
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const mealPlansLoaded = useSelector(selectMealPlansLoaded);
  const mealPlans = useSelector(selectMealPlans);
  const [showModal, setShowModal] = useState(false);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';

  const handleProductAdded = () => {
    setShowModal(false);
  }
 
  return (
    <div className='add-to-meal-plan bg-black'>
      {!token ? (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
        >
        <Modal.Header closeButton>
        </Modal.Header>
          <Modal.Body className='font-barlow'>
            <div className='text-center fs-3'>
              <p className='mt-2'>Please Log-in/Register<br /> To Add Items To Your Favorite Meals.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-dark" onClick={() => {setShowModal(false);navigate(`/my-account${urlParams}`);}}>
              Log-in or Register
            </Button>
          </Modal.Footer>
        </Modal>) : (
        mealPlansLoaded && <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
        >
          <Modal.Body className='font-barlow'>
            <div className='text-center my-4 fs-3'>
              <FontAwesomeIcon
                role="button"
                icon={faRegularHeart}
                size={'3x' as SizeProp}
              />
              <p className='mt-3'>Add Meal To Favorites</p>
            </div>
            <div className="text-center mb-4">
              <span className="fs-6">Create lists of your favorite MightyMeals to quickly add them to your cart from your&nbsp;
                <Link
                  to={`/my-account/my-meal-plans${urlParams}`}
                  className='text-blue'
                >
                  account page
                </Link> to save you time!</span>
            </div>
            <MealPlanForm
              product={product}
              onCancel={() => setShowModal(false)}
              onProductAdded={handleProductAdded}
            />
          </Modal.Body>
        </Modal>
      )}
      <FontAwesomeIcon
        role="button"
        icon={faSolidHeart}
        color={`${mealPlans.some((plan: any) => 
          plan.items.some((item: any) => 
          item.productId === product.data.id)) ? 'red' : 'white'}`}
        size={'xl' as SizeProp}
        onClick={() => setShowModal(true)}
      />
    </div>
  );
}