import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Spinner } from 'react-bootstrap';
import {
  selectCartUpdateInProgress,
  setCart
} from '../cart/cartSlice';
import OrderObj from './orderObj';
import CustomerObj from '../customer/customerObj';
import { selectProducts } from '../products/productsSlice';
import { selectCustomer } from '../customer/customerSlice';
import { selectToken } from '../user/userSlice';
import { selectIsMobileRoute } from '../mobile/mobileSlice';

interface Props {
  order: OrderObj;
}

export default function OrderAgainButton({ order }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
  const products = useSelector(selectProducts);
  const customerData = useSelector(selectCustomer);
  const customer = useMemo(() => {
    return new CustomerObj(customerData);
  }, [customerData]);
  const token = useSelector(selectToken);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';

  const handleCheckout = () => {
    setShowModal(false);
    navigate(`/checkout${urlParams}`);
  }

  const handleOrderAgain = async () => {
    setSelectedId(order.data.id);
    const cartItems = order.lineItemsToCartItems(products, customer);
    await dispatch(setCart({ token: token, cart_items: cartItems }));
    setShowModal(true);
    setSelectedId([]);
  }

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>MightyMeals Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Available items have been added to your cart.</p>
          <div className='d-flex justify-content-end gap-2'>
            <Button variant="outline-dark" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="dark" onClick={handleCheckout}>Checkout</Button>
          </div>
        </Modal.Body>
      </Modal>
      <Button
        size="sm"
        className='bg-green border-green'
        onClick={handleOrderAgain}
        disabled={cartUpdateInProgress}
      >
        {(cartUpdateInProgress && selectedId === order.data.id) ?
          <>
            Order again &nbsp;
            <Spinner
              animation="border"
              as="span"
              size="sm"
            />
          </> : 'Order again'}
      </Button>
    </>
  )
}