import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormikErrors, useFormik } from "formik";
import { Button, Form, Spinner } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import OrderObj from "./orderObj";
import DeliveryObj from '../delivery/deliveryObj';
import { selectDeliveryData, selectDeliveryLoaded } from '../delivery/deliverySlice';
import { selectToken } from "../user/userSlice";
import DeliveryAPI from "../../API/deliveryAPI";
import { selectCustomer } from "../customer/customerSlice";
import { selectUpdatingOrder, setUpdatingOrder, updateOrderDeliveryDate } from "./ordersSlice";
import { selectShippingMethods } from "../shipping/shippingSlice";
import ShippingMethodObj from "../shipping/shippingMethodObj";
import { selectProducts } from "../products/productsSlice";
import { selectCartCoupon } from "../cart/cartSlice";
import CartObj from "../cart/cartObj";
import { selectUtmShipping, selectUtmThreshold } from "../mobile/mobileSlice";

interface Props {
  order: OrderObj;
}

interface FormValues {
  delivery_date: Date | undefined;
  shipping_method?: number | undefined;
}

export default function ChangeDeliveryDate({ order }: Props) {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [changingDate, setChangingDate] = useState(false);
  const deliveryInfo = new DeliveryObj(useSelector(selectDeliveryData));
  const deliveryLoaded = useSelector(selectDeliveryLoaded);
  const deliveryDate = order.getDeliveryDateTime();
  const token = useSelector(selectToken);
  const customer = useSelector(selectCustomer);
  const updatingOrder = useSelector(selectUpdatingOrder);
  const shippingMethods = useSelector(selectShippingMethods);
  const products = useSelector(selectProducts);
  const coupon = useSelector(selectCartCoupon);
  const utmThreshold = useSelector(selectUtmThreshold);
  const utmShipping = useSelector(selectUtmShipping);
  const [shippingMethodbyZip, setShippingMethodByZip] = useState<ShippingMethodObj[]>([]);
  const isUtmThreshold = (utmThreshold && utmThreshold.ship_threshold_value) ? true : false;
  const isUtmShipping = (utmShipping && utmShipping.ship_price_value) ? true : false;
  const utmThresholdValue = utmThreshold && utmThreshold.ship_threshold_value;
  const utmShippingValue = utmShipping && utmShipping.ship_price_value;
  const zip = order.data.shipping.postcode ? order.data.shipping.postcode : order.data.billing.postcode;
  const cartItems = order.data.line_items.reduce((result: any, item: any) => {
    const productId = item.product_id.toString();
    const productQty = item.quantity;
    const productPrice = parseFloat(item.total);

    result[productId] = {
      product_id: parseInt(productId),
      product_qty: productQty,
      product_price: productPrice
    };

    return result;
  }, {});

  const cart = useMemo(() => {
    return new CartObj(cartItems)
  }, [cartItems]);
  let isShipping: ShippingMethodObj[] = [];

  useEffect(() => {
    getShippingMethodDates(zip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zip])

  const isDeliveryDay = (date: Date) => {
    const today = new Date();
    const blockedDates = deliveryInfo.getBlockedDates(order.data.shipping.city, zip);

    if (shippingMethodbyZip[0]?.data.ups_delivery_method === true) {
      const isDisabledTuesday = blockedDates.filter((blockedDate: any) => {
        const blockedDay = new Date(blockedDate).getDay();
        return blockedDay === 2;
      });

      if (isDisabledTuesday.length > 0) {
        let isNextWednesdayEnabled = false;

        isDisabledTuesday.forEach((tuesday: any) => {
          const disabledTuesday = new Date(tuesday);
          const nextWednesday = new Date(tuesday);
          nextWednesday.setDate(disabledTuesday.getDate() + 1);

          if (date.toDateString() === nextWednesday.toDateString()) {
            isNextWednesdayEnabled = true;
          }
        });

        if (isNextWednesdayEnabled) {
          return true;
        }
      }

      if (today.getDay() === 2 || today.getDay() === 3) {
        const nextThursday = new Date(today);
        const nextFriday = new Date(today);
        nextThursday.setDate(today.getDate() + ((4 + 7 - today.getDay()) % 7));
        nextFriday.setDate(today.getDate() + ((5 + 7 - today.getDay()) % 7));
        if (date.toDateString() === nextThursday.toDateString() || date.toDateString() === nextFriday.toDateString()) {
          return false;
        }
      } else if (today.getDay() > 4 || today.getDay() === 1 || today.getDay() === 0) {
        const nextWednesday = new Date(today);
        const nextTuesday = new Date(today);
        nextTuesday.setDate(today.getDate() + ((2 + 7 - today.getDay()) % 7));
        nextWednesday.setDate(today.getDate() + ((3 + 7 - today.getDay()) % 7));
        if (date.toDateString() === nextTuesday.toDateString()) {
          return false;
        }
        if (date.toDateString() === nextWednesday.toDateString()) {
          return false;
        }
      }

      return deliveryInfo.getDeliveryDays(order.data.shipping.city, zip, shippingMethodbyZip[0]).includes(date.getDay());
    } else {
      return deliveryInfo.getDeliveryDays(order.data.shipping.city, zip, shippingMethodbyZip[0]).includes(date.getDay());
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let date = e as unknown as Date;
    formik.setFieldValue('delivery_date', date);
  }

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};

    if (!values.delivery_date) {
      errors.delivery_date = "Required";
    } else if (!deliveryInfo.dateIsAvailable(new Date(values.delivery_date),
      order.data.shipping.city, order.data.shipping.postcode, shippingMethodbyZip[0])
    ) {
      errors.delivery_date = "That date is unavailable, Please choose another."
    }
    return errors;
  }

  const getShippingMethodDates = (zipCode: any) => {
    let matchedMethods = [];

    let hasFreeDelivery = false;

    for (const method of shippingMethods) {
      const sm = new ShippingMethodObj(method);
      if (sm.isMatch(zipCode, cart, products, coupon, customer, isUtmThreshold, utmThresholdValue)) {
        if (cart.hasProductWithCategory('mighty-bucks-gift-card', products) && sm.data.title === "Free Email Delivery") {
          hasFreeDelivery = false;
          matchedMethods.push(sm);
        } else if (!cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
          if ((sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping") || sm.data.cost === 0) {
            hasFreeDelivery = true;
            matchedMethods.push(sm);
          } else if (!hasFreeDelivery && (sm.data.title !== "Free Home Delivery" || sm.data.title !== "Free Shipping")) {
            matchedMethods.push(sm);
            if (sm.data.ups_delivery_method === true && isShipping.length === 0) {
              isShipping.push(sm);
            }
          }
        }

      }
    }
    const UPSshipping = matchedMethods.filter(sm => sm.data.ups_delivery_method === true);

    if (hasFreeDelivery) {
      matchedMethods = matchedMethods.filter(sm => (sm.data.title === "Free Home Delivery" || sm.data.title === "Free Shipping"));
    }

    if (isUtmThreshold || isUtmShipping) {
      if (matchedMethods?.length > 0) {
        const originalMethod = matchedMethods[0];

        const newData = JSON.parse(JSON.stringify({
          ID: originalMethod.data.ID,
          title: originalMethod.data.title,
          cost: originalMethod.data.cost,
          ups_delivery_method: originalMethod.data.ups_delivery_method,
          condition_groups: originalMethod.data.condition_groups
        }));

        if (utmThresholdValue !== "0" && isUtmThreshold) {
          const subtotalGroup = newData.condition_groups[0]?.find((group: { condition: string; }) => group.condition === "subtotal");
          const otherGroups = newData.condition_groups[0]?.filter((group: { condition: string; }) => group.condition !== "subtotal") || [];

          if (subtotalGroup) {
            newData.condition_groups[0] = [
              ...otherGroups,
              { ...subtotalGroup, value: utmThresholdValue }
            ];
          }
        }

        if (utmShippingValue && newData.cost !== "0" && newData.ups_delivery_method === true) {
          newData.cost = utmShippingValue;
        }

        const updatedMethod = new ShippingMethodObj({
          ...originalMethod,
          data: newData
        });

        updatedMethod.data = newData;

        matchedMethods = [updatedMethod];
      }
    }

    if (UPSshipping.length > 0) {
      setShippingMethodByZip(UPSshipping);
    } else {
      setShippingMethodByZip(matchedMethods);
    }
  }

  const formik = useFormik({
    initialValues: {
      delivery_date: deliveryDate ? deliveryDate : undefined
    },
    validate,
    onSubmit: values => {
      setErrorMsg('');
      if (!values.delivery_date) {
        setErrorMsg('Delivery date is required.');
        return;
      }

      const deliveryDate = values.delivery_date.getFullYear().toString() + '-' +
        ('0' + (values.delivery_date.getMonth() + 1)).slice(-2) + '-' +
        ('0' + values.delivery_date.getDate()).slice(-2);
      setChangingDate(true);
      dispatch(setUpdatingOrder(true));
      DeliveryAPI.updateOrderDeliveryDate(
        token,
        order.data.id,
        customer.id,
        deliveryDate
      ).then((response: string) => {
        dispatch(updateOrderDeliveryDate({
          order_id: order.data.id, delivery_date: response
        }));
        setShowForm(false);
      }).catch((e) => {
        setErrorMsg(e.message);
      }).finally(() => {
        dispatch(setUpdatingOrder(false));
        setChangingDate(false);
        // dispatch(loadOrdersList(customer.id));
      });
    }
  });

  if (!deliveryLoaded) {
    return (
      <></>
    );
  }

  if (!showForm) {
    return (
      <Button
        variant='dark'
        className='change-delivery-date bg-black'
        size="sm"
        onClick={() => setShowForm(true)}
      >
        Change delivery date
      </Button>
    );
  }

  return (
    <Form className='change-delivery-date-form' onSubmit={formik.handleSubmit}>
      {errorMsg &&
        <p className='text-danger'>{errorMsg}</p>}
      <Form.Group className="form-group required mb-2">
        <Form.Control
          as={DatePicker}
          id="delivery_date"
          type="text"
          isInvalid={Boolean(formik?.errors?.delivery_date)}
          autoComplete="date"
          calendarStartDay={1}
          filterDate={isDeliveryDay}
          excludeDates={deliveryInfo.getBlockedDates(order.data.shipping.city,
            order.data.shipping.postcode, shippingMethodbyZip[0])}
          selected={formik.values.delivery_date}
          minDate={deliveryInfo.getStartDate(order.data.shipping.postcode)}
          maxDate={deliveryInfo.getEndDate()}
          onChange={handleChange}
        />
        {formik.errors.delivery_date &&
          <div className='text-danger mt-1'>
            {formik.errors.delivery_date}
          </div>
        }
      </Form.Group>
      <Button
        className="me-2"
        variant="secondary"
        size="sm"
        disabled={changingDate}
        onClick={() => setShowForm(false)}
      >
        Cancel
      </Button>
      {changingDate ?
        <Button variant="dark" disabled>
          <Spinner animation="border" as="span" size="sm" />
          &nbsp;&nbsp;Changing date ...
        </Button> :
        <Button
          variant="dark"
          size="sm"
          type="submit"
          disabled={updatingOrder}
        >
          Change date
        </Button>
      }
    </Form>
  );
}