import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import {
  selectProductDietRestrictionsFilter,
  addDietaryRestriction,
  clearDietaryRestrictions,
  removeDietaryRestriction
} from '../../productsSlice';
import { createDietaryPlan, selectDietaryPlans, updateDietaryPlan } from '../../../dietary_plans/dietarySlice';
import { selectToken } from '../../../user/userSlice';
import DietaryPlansAPI from '../../../../API/dietaryAPI';
import DietaryPlanObj from '../../../dietary_plans/dietaryObj';
import { useLocation } from 'react-router-dom';

export default function DietaryRestrictionsFilterForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const filterValue = useSelector(selectProductDietRestrictionsFilter);
  const dietaryPlans = useSelector(selectDietaryPlans);
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const token = useSelector(selectToken);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMsg, setErrorMsg] = useState('');
  const [busyInSave, setBusyInSave] = useState(false);
  const [busyIndelete, setBusyInDelete] = useState(false);
  const [saved, setSaved] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const checkedElements = Array.from(document.querySelectorAll(':checked')) as unknown as string[];
  const checkedElementsValues = checkedElements.map((element: any) => element.id) as string[];
  let checkedElementsTrue = checkedElementsValues.filter((v) => v === 'dairy-free' || v === 'gluten-free' || v === 'not-spicy' || v === 'nut-free' || v === 'shellfish-free' || v === 'soy-free' || v === 'wheat-free' || v === 'sesame-free' || v === 'vegan');

  useEffect(() => {
    if(checkedElementsValues?.length > 0 && location.pathname.startsWith('/order')){
      setDisabled(false);
    }
    else{
      setDisabled(true);      
    }
  }, [checkedElementsValues?.length, location.pathname])
  useEffect(() => {
    setTimeout(() => {
      setSaved(false);
      setDeleted(false);
    }, 1500);
  }, [saved, deleted])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    if (checked && (id === 'dairy-free' || 'gluten-free' || 'not-spicy' || 'nut-free' || 'shellfish-free' || 'soy-free' || 'wheat-free' || 'sesame-free' || 'vegan')) {
      dispatch(addDietaryRestriction(e.target.id));
      setCheckedValues([...checkedValues, id]);
    } else if (!checked && (id === 'dairy-free' || 'gluten-free' || 'not-spicy' || 'nut-free' || 'shellfish-free' || 'soy-free' || 'wheat-free' || 'sesame-free' || 'vegan')) {
      dispatch(removeDietaryRestriction(e.target.id));
      setCheckedValues(checkedValues.filter((v) => v !== id));
    }
  }
  const handleDeleteFilter = () => {
    if (dietaryPlans?.length === 0) {
      dispatch(clearDietaryRestrictions());
    }
    else{
      setBusyInDelete(true);
      dispatch(clearDietaryRestrictions());
      let newArray = {
        id: dietaryPlans[0]?.id,
        dietary_plan: []
      }
      let MydietaryPlan = DietaryPlanObj.getById(dietaryPlans, parseInt(dietaryPlans[0]?.id));
      if (!MydietaryPlan) {
        setErrorMsg('An unexpected error has occurred. Please try again later.');
        return;
      }
      DietaryPlansAPI.updateDietaryPlan(token, newArray).then((dietaryPlan) => {
        dispatch(updateDietaryPlan(dietaryPlan));
      }).catch((e) => {
        console.error(e);
        setErrorMsg("An unexpected error occurred. Please try again later.");
      }).finally(() => {
        setBusyInDelete(false);
        setDeleted(true);
      });
    }
  }

  const handleAllSave = () => {
    setBusyInSave(true);
    if (dietaryPlans?.length === 0) {
      DietaryPlansAPI.createDietaryPlan(
        token, {
        dietary_plan: checkedElementsTrue
      }).then((dietaryPlan) => {
        dispatch(createDietaryPlan(dietaryPlan));
      }).catch((e) => {
        console.error(e);
        setErrorMsg("An unexpected error occurred. Please try again later.");
      }).finally(() => {
        setBusyInSave(false);
        setSaved(true);
      });
    }
    else {
      let newArray = {
        id: dietaryPlans[0]?.id,
        dietary_plan: checkedElementsTrue
      }
      let MydietaryPlan = DietaryPlanObj.getById(dietaryPlans, parseInt(dietaryPlans[0]?.id));
      if (!MydietaryPlan) {
        setErrorMsg('An unexpected error has occurred. Please try again later.');
        return;
      }
      DietaryPlansAPI.updateDietaryPlan(token, newArray).then((dietaryPlan) => {
        dispatch(updateDietaryPlan(dietaryPlan));
      }).catch((e) => {
        console.error(e);
        setErrorMsg("An unexpected error occurred. Please try again later.");
      }).finally(() => {
        setBusyInSave(false);
        setSaved(true);
      });
    }
  }

  return (
    <>
      <Form className='fs-6 mx-2'>
        <Form.Check
          type="checkbox"
          name="product-diet-restrictions"
          label="Dairy Free"
          id="dairy-free"
          checked={filterValue.includes('dairy-free')}
          onChange={handleChange}
        />
        <Form.Check
          type="checkbox"
          name="product-diet-restrictions"
          label="Gluten Free"
          id="gluten-free"
          checked={filterValue.includes('gluten-free')}
          onChange={handleChange}
        />
        <Form.Check
          type="checkbox"
          name="product-diet-restrictions"
          label="Not Spicy"
          id="not-spicy"
          checked={filterValue.includes('not-spicy')}
          onChange={handleChange}
        />
        <Form.Check
          type="checkbox"
          name="product-diet-restrictions"
          label="Nut Free"
          id="nut-free"
          checked={filterValue.includes('nut-free')}
          onChange={handleChange}
        />
        <Form.Check
          type="checkbox"
          name="product-diet-restrictions"
          label="Shellfish Free"
          id="shellfish-free"
          checked={filterValue.includes('shellfish-free')}
          onChange={handleChange}
        />
        <Form.Check
          type="checkbox"
          name="product-diet-restrictions"
          label="Soy Free"
          id="soy-free"
          checked={filterValue.includes('soy-free')}
          onChange={handleChange}
        />
        <Form.Check
          type="checkbox"
          name="product-diet-restrictions"
          label="Wheat Free"
          id="wheat-free"
          checked={filterValue.includes('wheat-free')}
          onChange={handleChange}
        />
        <Form.Check
          type="checkbox"
          name="product-diet-restrictions"
          label="Sesame Free"
          id="sesame-free"
          checked={filterValue.includes('sesame-free')}
          onChange={handleChange}
        />
      </Form>
      {!token && <Button variant="link" size='sm' className='text-black' disabled={disabled} onClick={() => handleDeleteFilter()}>Clear</Button>}
      {token && 
        (<>
          <Button variant="link" size='sm' className='text-black px-2 float-start' disabled={disabled} onClick={() => handleDeleteFilter()}>
            {busyIndelete ? 'Removing..' : deleted ? 'Removed!' : 'Remove'}
          </Button>
          <Button variant="link" size='sm' className='text-black px-2 float-end' disabled={disabled} onClick={() => handleAllSave()}>
            {busyInSave ? 'Saving..' : saved ? 'Saved!' : 'Save'}
          </Button>
      </>)}
    </>
  );
}