import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Cart from '../features/cart/Cart';
import ProductFilters from '../features/products/filters/ProductFilters';
import ProductList from '../features/products/ProductList';
import CartObj from '../features/cart/cartObj';
import { useDispatch, useSelector } from 'react-redux';
import { selectCartItems, selectCartZipcode, setCartItem } from '../features/cart/cartSlice';
import {
  addDietaryRestriction,
  loadProductBySlug,
  selectProducts,
  setProductDietFilter,
  selectProductLoadError,
  selectProductsPagesLoaded,
  selectProductsTotalPages,
  setProductProteinTypeFilter,
  setProductTypeFilter,
  selectProductDietRestrictionsFilter,
  clearDietaryRestrictions,
  setProductCalorieFilter,
  setProductCarbFilter,
  setProductFatFilter,
  setProductProteinFilter,
  setProductSearchFilter,
  setProductSortFilter,
} from '../features/products/productsSlice';
import Window from '../utils/Window';
import DeliveryZip from '../features/cart/DeliveryZip';
import DeliveryDate from '../features/cart/DeliveryDate';
import ProductFiltersModal from '../features/products/filters/ProductFiltersModal';
import ScrollToTopButton from '../components/ScrollToTopButton';
import {
  loadAffiliate,
  loadLogoUrl,
  postVisit,
  resetReferrals,
  selectAffiliate,
  selectAffiliateLoaded,
  selectAffiliateLoading,
  selectLoadingLogoUrl,
  selectPostingVisit,
  selectVisitPosted,
  setEmbedded
} from '../features/referrals/referralsSlice';
import { selectSessionIsLoaded, selectToken } from '../features/user/userSlice';
import ProductSearch from '../features/products/filters/ProductSearch';
import {
  loadMealPlans,
  selectMealPlansLoadError,
  selectMealPlansLoading,
  selectMealPlansLoaded
} from '../features/meal_plans/mealPlansSlice';
import ProductObj from '../features/products/productObj';
import { loadDietaryPlans, selectDietaryPlans, selectDietaryPlansLoaded, selectDietaryPlansLoadError, selectDietaryPlansLoading } from '../features/dietary_plans/dietarySlice';
import AffiliateObj from '../features/referrals/AffiliateObj';
import { selectGeneralOptions, selectIPAddress, selectIsMobileRoute, setIsGiftCardInCart } from '../features/mobile/mobileSlice';
import MealFromURL from '../features/meal_plans/MealFromURL';
import MobileFooter from '../components/MobileFooter';
import ProductsAPI from '../API/productsAPI';

interface Props {
  errorMsg?: string;
  submitAutoshipOrder?: () => void;
}

export default function OrderPage({ submitAutoshipOrder, errorMsg }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [searchParams] = useSearchParams();
  const params = useParams();
  const productSlug = useParams().productSlug;
  const queryParams = new URLSearchParams(location.search);
  const productCodeQuery = queryParams.get("product_id");
  const cartItems = useSelector(selectCartItems);
  const products = useSelector(selectProducts);
  const productPagesLoaded: number = useSelector(selectProductsPagesLoaded);
  const totalProductPages = useSelector(selectProductsTotalPages);
  const productLoadError: boolean = useSelector(selectProductLoadError);
  const cartZip = useSelector(selectCartZipcode);
  const cart = useMemo(() => {
    return new CartObj(cartItems)
  }, [cartItems]);
  const token = useSelector(selectToken);
  const sessionLoaded = useSelector(selectSessionIsLoaded);
  const visitPosted = useSelector(selectVisitPosted);
  const postingVisit = useSelector(selectPostingVisit);
  const affiliateLoaded = useSelector(selectAffiliateLoaded);
  const affiliate = useSelector(selectAffiliate);
  const mealPlansLoaded = useSelector(selectMealPlansLoaded);
  const mealPlansLoadError = useSelector(selectMealPlansLoadError);
  const mealPlansLoading = useSelector(selectMealPlansLoading);
  const dietaryPlansLoaded = useSelector(selectDietaryPlansLoaded);
  const dietaryPlansLoadError = useSelector(selectDietaryPlansLoadError);
  const dietaryPlansLoading = useSelector(selectDietaryPlansLoading);
  const filterValue = useSelector(selectProductDietRestrictionsFilter);
  const dietaryPlans = useSelector(selectDietaryPlans);
  const AppURL = useSelector(selectIsMobileRoute);
  const QURLParams = AppURL ? `/?${AppURL}` : '';
  const AndURLParams = AppURL ? `&${AppURL}` : '';
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const zipPrompt = getGeneralOptions?.zip_prompt;
  const ipAddress = useSelector(selectIPAddress);
  const [isAffiliateLoaded, setIsAffiliateLoaded] = useState(false);
  const [isCartZipDone, setIsCartZipDone] = useState(false);
  const affiliateLogoUrlLoading = useSelector(selectLoadingLogoUrl);
  const affiliateLoading = useSelector(selectAffiliateLoading);
  const affiliateParamSlug = params?.affiliateSlug !== (affiliate?.['meta']?.['custom_slug'] ? affiliate['meta']['custom_slug'][0] : null);
  const [userItems, setUserItems] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const couponCodeQuery = queryParams.get("coupon-code");
  const isAutoShipOrder = location.pathname === "/autoship/menu";

  useEffect(() => {
    checkZipCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems, isCartZipDone, zipPrompt]);

  useEffect(() => {
    if (params?.affiliateSlug && affiliateParamSlug) {
      dispatch(resetReferrals());
    }
  }, [affiliateParamSlug, dispatch, params?.affiliateSlug])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const productType = searchParams.get('product_type');
    if ((location.pathname.startsWith('/order') && productType)) {
      handleLoadProduts(productType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname, location.search])

  const handleLoadProduts = (productType: any) => {
    //dispatch(loadProductBySlug(productType));
    dispatch(setProductTypeFilter(productType));
    dispatch(setProductProteinTypeFilter('all'));
    dispatch(setProductDietFilter('all'));
    dispatch(clearDietaryRestrictions());
    dispatch(setProductCalorieFilter({ min: 0, max: 1200 }));
    dispatch(setProductCarbFilter({ min: 0, max: 250 }));
    dispatch(setProductFatFilter({ min: 0, max: 100 }));
    dispatch(setProductProteinFilter({ min: 0, max: 150 }));
    dispatch(setProductSearchFilter(''));
    dispatch(setProductSortFilter('none'));
  }

  const adjustCartLayout = () => {
    const navRect = document.querySelector('nav.navbar')?.getBoundingClientRect();
    const headerEl = document.querySelector('.cart-column .cart-header');
    const headerRect = headerEl?.getBoundingClientRect();
    const bodyEl = document.querySelector('.cart-column .cart-items');
    const bodyRect = bodyEl?.getBoundingClientRect();
    const footerEl = document.querySelector('.cart-column .cart-footer');
    const footerRect = footerEl?.getBoundingClientRect();

    if (!navRect || !headerRect || !bodyRect || !footerRect) return;

    if (footerRect.bottom > window.innerHeight - 10 &&
      (footerEl as HTMLElement).style.position !== 'sticky'
    ) {
      (footerEl as HTMLElement).style.position = 'sticky';
      (footerEl as HTMLElement).style.zIndex = '999';
      (footerEl as HTMLElement).style.width = footerRect.width + 'px';
      // (footerEl as HTMLElement).style.padding = '10.5px';
      // (footerEl as HTMLElement).style.bottom = '10px';

      (bodyEl as HTMLElement).style.maxHeight =
        window.innerHeight - footerRect.height - headerRect.bottom + 'px';
      (bodyEl as HTMLElement).style.overflowY = 'auto';
    }

    if ((footerEl as HTMLElement).style.position === 'sticky' &&
      (navRect.bottom + headerRect.height + bodyRect.height + footerRect.height) <
      window.innerHeight - 10
    ) {
      footerEl?.removeAttribute('style');
      bodyEl?.removeAttribute('style');
    }
  }

  const handleWindowResize = () => {
    const filtersEl = document.querySelector('.product-filters');
    const cartEl = document.querySelector('.cart-column');
    const footerEl = document.querySelector('.cart-column .cart-footer');
    const bodyEl = document.querySelector('.cart-column .cart-items');

    footerEl?.removeAttribute('style');
    bodyEl?.removeAttribute('style');

    filtersEl?.removeAttribute('style');
    cartEl?.removeAttribute('style');

    adjustCartLayout();
    handleWindowScroll();

  }

  const handleWindowScroll = () => {
    if (Window.isMobile()) return;

    const navRect = document.querySelector('nav.navbar')?.getBoundingClientRect();
    const filtersEl = document.querySelector('.product-filters');
    const filtersRect = filtersEl?.getBoundingClientRect();
    const cartEl = document.querySelector('.cart-column');
    const cartRect = cartEl?.getBoundingClientRect();

    if (!navRect || !filtersRect || !cartRect) return;

    (cartEl as HTMLElement).style.maxHeight = window.innerHeight - navRect.bottom + 'px';
    if (navRect.top === 0) {
      // Pin filters under navbar
      (filtersEl as HTMLElement).style.position = 'sticky';
      (filtersEl as HTMLElement).style.top = navRect.bottom + 'px';
      (filtersEl as HTMLElement).style.maxWidth = filtersRect.width + 'px';
      (filtersEl as HTMLElement).style.zIndex = '949';

      // Pin cart column under navbar
      (cartEl as HTMLElement).style.position = 'sticky';
      (cartEl as HTMLElement).style.top = navRect.bottom + 'px';
      (cartEl as HTMLElement).style.right = '0';
      (cartEl as HTMLElement).style.zIndex = '949';
    }

    if (navRect.top > 0) {
      filtersEl?.removeAttribute('style');
      cartEl?.removeAttribute('style');
    }
  }

  useEffect(() => {
    if (!affiliateLoading && !affiliateLoaded) {
      async function fetchAffiliate() {
        try {
          if (params?.affiliateSlug) {
            await dispatch(loadAffiliate(params.affiliateSlug));
            setIsAffiliateLoaded(true);
            navigate(`/order${QURLParams}`);
          }
        } catch (error) {
          console.error(error);
        }
      }

      fetchAffiliate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateLoaded, affiliateLoading, dispatch, params.affiliateSlug]);

  useEffect(() => {
    if (affiliateLoaded && !affiliateLogoUrlLoading && isAffiliateLoaded) {
      const fetchData = async () => {
        const affiliateData = new AffiliateObj(affiliate);

        await dispatch(loadLogoUrl(affiliateData.getCustomSlug()));
        if (affiliateData.getCustomSlug().length > 0) {
          dispatch(setEmbedded(true));
        }
        setIsAffiliateLoaded(false);
        navigate(`/order${QURLParams}`);
      }
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate, affiliateLoaded, affiliateLogoUrlLoading, dispatch, isAffiliateLoaded, navigate]);

  // Set embedded to true if in search params
  useEffect(() => {
    if (searchParams.get('embedded')) {
      dispatch(setEmbedded(true));
    }
  }, [dispatch, searchParams]);

  // if guest user coming on referral link, post a visit, if not done already
  useEffect(() => {
    if (sessionLoaded && !token && affiliateLoaded && ipAddress && !visitPosted && !postingVisit) {
      dispatch(postVisit({ affiliateId: affiliate.affiliate_id, url: `${process.env.REACT_APP_WEB_URL}`, ip: ipAddress }))
    }
  }, [affiliate.affiliate_id, affiliateLoaded, dispatch, ipAddress, postingVisit, sessionLoaded, token, visitPosted]);

  useEffect(() => {
    if (searchParams.get('delivery')) {
      setShowModal(true);
    }
  }, [searchParams]);

  useEffect(() => {
    adjustCartLayout();
  });

  // // When items are added to cart see if we need to stick cart footer to bottom
  useEffect(() => {
    adjustCartLayout();
  }, [cartItems])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const couponCode = searchParams.get('coupon-code');
    if (cart.hasProductWithCategory('gift-card', products) || cart.hasProductWithCategory('mighty-bucks-gift-card', products)) {
      dispatch(setIsGiftCardInCart(true));
      if (couponCode) {
        navigate(`/checkout?coupon-code=${couponCode}${AndURLParams}`);
      }
      else {
        navigate(`/checkout/?${QURLParams}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, dispatch, location.search, navigate, products]);


  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);

    return () => window.removeEventListener('scroll', handleWindowScroll);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line
  }, []);

  // Process filter params
  useEffect(() => {
    const productType = searchParams.get('product_type');

    if (productType && ['all', 'standard-menu', 'bulk', 'snacks', 'gift-card', 'breakfast',
      'giftcard-donation'].includes(productType)
    ) {
      dispatch(setProductTypeFilter(productType));
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    const protein = searchParams.get('protein');

    if (protein && ['all', 'beef', 'chicken', 'fish-seafood', 'pork', 'turkey',
      'vegetarian'].includes(protein)
    ) {
      dispatch(setProductProteinTypeFilter(protein));
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    const diet = searchParams.get('diet');

    if (diet && ['all', 'breakfast', 'emp180-approved', 'low-carb',
      'low-sodium', 'under-500-cal', 'over-500-cal'].includes(diet)
    ) {
      dispatch(setProductDietFilter(diet));
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    const restrictionsParam = searchParams.get('restrictions');

    if (!restrictionsParam) return;

    let restrictions = restrictionsParam.split(',').filter((restriction) => {
      return ['dairy-free', 'gluten-free', 'not-spicy', 'nut-free',
        'shellfish-free', 'soy-free', 'wheat-free'].includes(restriction);
    });

    for (const restriction of restrictions) {
      dispatch(addDietaryRestriction(restriction));
    }
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (token && !mealPlansLoadError && !mealPlansLoading && !mealPlansLoaded) {
      dispatch(loadMealPlans(token));
    }
  }, [token, mealPlansLoadError, mealPlansLoading, mealPlansLoaded, dispatch]);

  useEffect(() => {
    if (token) {
      if (location.pathname.startsWith('/order')) {
        const reduxValues: string[] = filterValue;
        const DBValues: string[] = dietaryPlans[0]?.dietary_plan;

        if (reduxValues.length === 0) {
          // eslint-disable-next-line array-callback-return
          DBValues?.map((item: any) => {
            dispatch(addDietaryRestriction(item));
          })
        } else if (reduxValues.length > 0) {
          dispatch(clearDietaryRestrictions());
          DBValues?.forEach(value => {
            if (!reduxValues.includes(value)) {
              reduxValues?.push(value);
            }
          });
          // eslint-disable-next-line array-callback-return
          reduxValues?.map((items: any) => {
            dispatch(addDietaryRestriction(items));
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dietaryPlans, dispatch, location.pathname, location.search])

  useEffect(() => {
    if (token && !dietaryPlansLoadError && !dietaryPlansLoading && !dietaryPlansLoaded) {
      dispatch(loadDietaryPlans(token));
    }
  }, [token, dietaryPlansLoadError, dietaryPlansLoading, dietaryPlansLoaded, dispatch]);

  useEffect(() => {
    if ((totalProductPages && (productPagesLoaded < totalProductPages)) &&
      !productLoadError && productSlug) {
      let product = ProductObj.getBySlug(products, productSlug);
      if (!product) {
        dispatch(loadProductBySlug({ slug: productSlug, isCategory: false }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productLoadError, productPagesLoaded, productSlug, totalProductPages]);

  useEffect(() => {
    const fetchData = async () => {
      if (productCodeQuery && (productCodeQuery !== "" || productCodeQuery !== null)) {
        setIsLoading(true);
        await ProductsAPI.loadProductsByURL(productCodeQuery).then((response: any) => {
          setUserItems(response);
          handleAddToCart(response);
        });
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCodeQuery, productPagesLoaded]);

  const checkZipCode = () => {
    if (zipPrompt === true) {
      if (productPagesLoaded !== 0 && !cart.isEmpty() && !cartZip) {
        setIsCartZipDone(false);
      } else {
        setIsCartZipDone(true);
      }
    } else {
      setIsCartZipDone(true);
    }
  }

  const handleZipChange = (isValid: boolean) => {
    if (isValid) {
      setIsCartZipDone(true);
    }
  }

  const handleAddToCart = async (userMeals: any) => {
    try {
      for (const meal of userMeals) {
        if (meal.stock_status !== 'outofstock') {
          const productId = parseInt(meal.id);
          const product = ProductObj.getById(userMeals, productId);
          if (product) {
            await dispatch(setCartItem({
              token: token,
              cart_item: {
                product_id: productId,
                product_qty: 1,
                product_price: product.data.price
              }
            }));
          }
        }
      }
    } catch (e) {
      console.error("Error:", e);
    } finally {
      if (couponCodeQuery) {
        navigate(`${location.pathname}?coupon-code=${couponCodeQuery}${AndURLParams}`);
      } else if (!cart.isEmpty()) {
        navigate(`${location.pathname}${QURLParams}`);
      }
      setIsLoading(false);
    }
  };

  return (
    <Container fluid className="order-page">
      {!isCartZipDone && !token &&
        <Modal show={true}>
          <Modal.Header className='border-bottom-0'>
            <div className='text-center fs-4'>
              <p className='mt-2'>Enter Zip Code to See If We Deliver To You.</p>
            </div>
          </Modal.Header>
          <Modal.Body className='font-barlow mb-4'>
            <DeliveryZip cart={cart} handleZipChange={handleZipChange} />
          </Modal.Body>
        </Modal>}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header className='border-bottom-0' closeButton>
        </Modal.Header>
        <Modal.Body>
          <p className='text-center fs-2'>Success!</p>
          <p className='text-center'>We deliver fresh meals to your door!</p>
        </Modal.Body>
        {searchParams.get('delivery') && <div className='d-flex justify-content-center p-3'>
          <Button variant="dark" onClick={() => { setShowModal(false); navigate(`/order${QURLParams}`) }}>Start Order</Button>
        </div>}
      </Modal>
      {productCodeQuery && <MealFromURL isLoading={isLoading} userItems={userItems} />}
      {Window.isMobile() && <ProductFiltersModal />}
      <Row className='mx-0'>
        <Col xs={12} lg={9} xxl={10} className='px-0'>
          {Window.isMobile() &&
            <div className='mx-1 my-2'>
              <Row>
                <Col xs={6} className='px-2'>
                  <ProductSearch className='mb-2' />
                </Col>
                <Col xs={6} className='px-2'>
                  {!isAutoShipOrder &&
                    <DeliveryZip cart={cart} />}
                </Col>
              </Row>
              {cartZip && !isAutoShipOrder &&
                <DeliveryDate cart={cart} />
              }
              <hr />
            </div>
          }
          {!Window.isMobile() && <ProductFilters />}
          {productPagesLoaded === 0 &&
            <div className='text-center my-5 h-100'>
              <Spinner
                variant="dark"
                animation="border"
                as="span"
                className='spinner-xl my-25px'
              />
            </div>}
          <ProductList />
          <ScrollToTopButton />
        </Col>
        {!(Window.isMobile() || Window.isTablet()) ?
          <Col sm={4} lg={3} xxl={2} className={`cart-column ${location.pathname.startsWith('/autoship/menu') && 'isautoshipmenucart'}`}>
            <Cart submitAutoshipOrder={submitAutoshipOrder} errorMsg={errorMsg} userItems={userItems} />
          </Col> : <MobileFooter submitAutoshipOrder={submitAutoshipOrder} errorMsg={errorMsg} />
        }
      </Row>
    </Container>
  );
}