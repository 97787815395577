import { ChangeEvent, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Form, Spinner } from 'react-bootstrap';
import { selectToken, selectUser } from '../user/userSlice';
import { selectCustomer, setCustomer } from './customerSlice';
import { FormikErrors, useFormik } from 'formik';
import CustomerAPI from '../../API/customerAPI';
import config from '../../config.json';
import Helper from '../../utils/Helper';
import GooglePlace from '../../components/GooglePlace';
import { useLocation, useNavigate } from 'react-router-dom';
import { setOrderInProgress } from '../cart/cartSlice';
import OrdersAPI from '../../API/ordersAPI';
import { updateOrder } from '../orders/ordersSlice';
import { selectIsMobileRoute } from '../mobile/mobileSlice';
import { selectGuestEmail } from '../guest/guestSlice';

interface Props {
  onClose: () => void;
}

interface FormValues {
  email: string;
  phone: string;
  first_name: string;
  last_name: string;
  address_1: string;
  address_2?: string;
  city: string;
  state: string;
  postcode: string;
}

export default function BillingAddressForm({ onClose }: Props) {
  const navigate = useNavigate();
  const streetRef = useRef<any>()
  const townRef = useRef<any>()
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector(selectToken);
  const [busy, setBusy] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const user = useSelector(selectUser);
  const customer = useSelector(selectCustomer);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const guestEmail = useSelector(selectGuestEmail);
  const billing_info = Object.keys(customer).length > 0 ? customer.billing : {};
  const isEditableOrder = location?.state && location.state.isEditOrder === true;
  const updateOrderId = location?.state && location?.state?.orderId;
  const isAutoship = location?.state && location?.state?.autoShip;

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = "Invalid";
    }
    if (!values.phone) {
      errors.phone = "Required";
    } else if (!/^\(\d{3}\) \d{3}-\d{4}$/.test(values.phone)) {
      errors.phone = "Invalid";
    }
    if (!values.first_name) {
      errors.first_name = "Required";
    }
    if (!values.last_name) {
      errors.last_name = "Required";
    }
    if (!values.address_1) {
      errors.address_1 = "Required";
    }
    if (!values.city) {
      errors.city = "Required";
    }
    if (!values.state) {
      errors.state = "Required";
    }
    if (!values.postcode) {
      errors.postcode = "Required";
    } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(values.postcode)) {
      errors.postcode = "Invalid"
    }
    return errors;
  }

  const initialEmailValue = () => {
    if (token && billing_info && billing_info.email) {
      return 'email' in billing_info ? billing_info.email : ''
    } else if (token && customer && customer.email) {
      return customer ? customer.email : ''
    }

    return guestEmail;
  }

  const formik = useFormik({
    initialValues: {
      email: initialEmailValue(),
      phone: Helper.formatPhoneNo(customer?.billing?.phone),
      first_name: customer?.billing?.first_name,
      last_name: customer?.billing?.last_name,
      address_1: customer?.billing?.address_1,
      address_2: customer?.billing?.address_2,
      city: customer?.billing?.city,
      state: customer?.billing?.state,
      postcode: customer?.billing?.postcode
    },
    validate,
    onSubmit: values => {
      setErrorMsg('');
      setBusy(true);
      if (isEditableOrder) {
        UpdateEditOrder(values);
      } else {
        CustomerAPI.updateCustomerBilling({
          customer_id: customer?.id,
          token: user.token,
          billing: { ...values, country: 'US' }
        }).then((response) => {
          if ('code' in response) {
            console.error(response);
            setErrorMsg("An error has occured. Please try again later.");
          } else if ('id' in response) {
            dispatch(setCustomer(response));
            onClose();
          }
        }).catch((e) => {
          setErrorMsg(e.message);
        }).finally(() => {
          setBusy(false);
          if (isAutoship) {
            navigate(`/autoship${urlParams}`);
          }
        });
      }
    }
  });

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("phone", Helper.formatPhoneNo(e.target.value));
  }

  const UpdateEditOrder = async (values: any) => {
    try {
      dispatch(setOrderInProgress(true));

      const orderData: Record<string, any> = {
        billing: { ...values, country: 'US' }
      };

      const response = await OrdersAPI.updateOrder(token, updateOrderId, orderData);
      OrdersAPI.createOrderNote(token, updateOrderId, "Customer changed billing address.");

      if ('id' in response) {
        dispatch(updateOrder(response));
        navigate(`/autoship/orders/${updateOrderId}${urlParams}`);
      }

    } catch (e) {
      console.error("Error While Updating Order: ", e);
    } finally {
      dispatch(setOrderInProgress(false));
      setBusy(false);
    }
  };

  return (
    <Card className="billing-address-form">
      <Card.Header><span className="fs-4">Billing Address</span></Card.Header>
      <Card.Body>
        <Form onSubmit={formik.handleSubmit}>
          {errorMsg && (
            <p className='text-danger'>{errorMsg}</p>)}
          <Row>
            <Col>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  id="email"
                  type="email"
                  isValid={Boolean(formik.values.email) &&
                    !Boolean(formik.errors.email)}
                  isInvalid={Boolean(formik.errors.email)}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>Mobile Phone</Form.Label>
                <Form.Control
                  id="phone"
                  type="text"
                  maxLength={14}
                  isValid={Boolean(formik.values.phone) &&
                    !Boolean(formik.errors.phone)}
                  isInvalid={Boolean(formik.errors.phone)}
                  value={formik.values.phone}
                  onChange={handlePhoneChange}
                />
                {formik.errors.phone &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.phone}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  id="first_name"
                  type="text"
                  isValid={Boolean(formik.values.first_name) &&
                    !Boolean(formik.errors.first_name)}
                  isInvalid={Boolean(formik.errors.first_name)}
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.first_name &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.first_name}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  id="last_name"
                  type="text"
                  isValid={Boolean(formik.values.last_name) &&
                    !Boolean(formik.errors.last_name)}
                  isInvalid={Boolean(formik.errors.last_name)}
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
                {formik.errors.last_name &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.last_name}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Street address</Form.Label>
            <GooglePlace formik={formik} streetRef={streetRef} townRef={townRef} name={'address_1'}
              city={'city'} state={'state'} postcode={'postcode'}
            />
            {formik.errors.address_1 &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.address_1}
              </Form.Control.Feedback>}
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Control
              id="address_2"
              type="text"
              placeholder="Apartment, suite, unit etc. (optional)"
              isInvalid={Boolean(formik.errors.address_2)}
              value={formik.values.address_2}
              onChange={formik.handleChange}
            />
            {formik.errors.address_2 &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.address_2}
              </Form.Control.Feedback>}
          </Form.Group>
          <Form.Group className="mb-3 form-group required">
            <Form.Label>Town / City</Form.Label>
            <Form.Control
              id="city"
              ref={townRef}
              type="text"
              name="city"
              placeholder="Enter Town/Area"
              value={formik.values.city}
              onChange={formik.handleChange}

            />
            {formik.errors.city &&
              <Form.Control.Feedback type="invalid">
                {formik.errors.city}
              </Form.Control.Feedback>}
          </Form.Group>
          <p>Delivery is to Residential addresses only. Non-residential deliveries
            will be canceled and are not refundable.
          </p>
          <Row>
            <Col>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>State</Form.Label>
                <Form.Select
                  id="state"
                  isValid={Boolean(formik.values.state) && !Boolean(formik.errors.state)}
                  isInvalid={Boolean(formik.errors.state)}
                  value={formik.values.state}
                  onChange={formik.handleChange}
                >
                  <option value="">-- select a state --</option>
                  {config.STATE_CODES.map((entry) => (
                    <option key={entry.code} value={entry.code}>{entry.state}</option>
                  ))}
                </Form.Select>
                {formik.errors.state &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.state}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 form-group required">
                <Form.Label>Postcode / ZIP</Form.Label>
                <Form.Control
                  id="postcode"
                  type="text"
                  maxLength={10}
                  placeholder="Enter Postcode / ZIP"
                  value={formik.values.postcode}
                  onChange={formik.handleChange}
                />
                {formik.errors.postcode &&
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.postcode}
                  </Form.Control.Feedback>}
              </Form.Group>
            </Col>
          </Row>
          <Button className="me-2" variant="outline-dark" onClick={onClose}>Cancel</Button>
          {busy ?
            <Button className='bg-black' size="lg" disabled>
              <Spinner animation="border" as="span" size="sm" />
              &nbsp;&nbsp;Saving ...
            </Button>
            :
            <Button className='bg-black' type="submit">Save address</Button>
          }
        </Form>
      </Card.Body>
    </Card>
  );
}
