import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import OrderObj from './orderObj';
import ChangeDeliveryDate from './ChangeDeliveryDate';
import OrderAgainButton from './OrderAgainButton';
import RefundOrder from './RefundOrder';
import { loadOrders, resetOrders, selectOrders, selectOrdersPagesLoaded, selectOrdersTotalPages } from './ordersSlice';
import { useState, useEffect } from 'react';
import { selectIsMobileRoute, setIsLoadMore } from '../mobile/mobileSlice';
import { selectToken } from '../user/userSlice';

export default function MyOrdersMobile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const allOrders: Array<Record<string, any>> = useSelector(selectOrders);
  const orders = allOrders.filter((order, index, self) => (order.status !== "autoship" ||
    order.status !== "mmfailpayment" || order.status !== "mmcancelled") &&
    index === self.findIndex((o) => o.id === order.id));
  const total_pages: number = useSelector(selectOrdersTotalPages);
  const pages_loaded: number = useSelector(selectOrdersPagesLoaded);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(resetOrders());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        await dispatch(loadOrders({ token }));
        await dispatch(setIsLoadMore(false));
        setIsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleRefetch = () => {
    dispatch(loadOrders({ token }));
    navigate(`/my-account/orders${urlParams}`);
  }

  const loadMoreOrders = async () => {
    setIsLoading(true);
    dispatch(setIsLoadMore(true));
    if (token && (!pages_loaded || pages_loaded < total_pages)) {
      await dispatch(loadOrders({ token }));
      await dispatch(setIsLoadMore(false));
      setIsLoading(false);
    }
  }

  return (
    <div className='my-orders-mobile my-5'>
      {orders.length <= 0
        ? <Card body className='my-1'>
          <Row className='my-1'>
            <Col xs={6} className='fw-bold'>Order</Col>
            <Col xs={6}>
              ---
            </Col>
          </Row>
          <Row className='my-1'>
            <Col xs={6} className='fw-bold'>Date</Col>
            <Col xs={6}>
              ---
            </Col>
          </Row>
          <Row className='my-1'>
            <Col xs={6} className='fw-bold'>Status</Col>
            <Col xs={6}>---</Col>
          </Row>
          <Row className='my-1'>
            <Col xs={6} className='fw-bold'>Delivery Date</Col>
            <Col xs={6}>---</Col>
          </Row>
          <Row className='my-1'>
            <Col xs={6} className='fw-bold'>Total</Col>
            <Col xs={6}>
              ---
            </Col>
          </Row>
          <Row className='my-1'>
            <Col xs={6} className='my-1 fw-bold'>Actions</Col>
            <Col xs={6} className='vstack gap-1'>
              <Button
                as={Link as any}
                to={`/order${urlParams}`}
                variant="link"
                className='text-start text-black p-0'
              >Order</Button>
            </Col>
          </Row>
        </Card>
        : <>
          {orders.map((orderData) => {
            const order = new OrderObj(orderData);
            return (
              <Card body className='my-1' key={order.data.id}>
                <Row className='my-1'>
                  <Col xs={6} className='fw-bold'>Order</Col>
                  <Col xs={6}>
                    <Button
                      as={Link as any}
                      to={'/my-account/orders/' + order.data.id + urlParams}
                      variant="link"
                      order={order}
                      className='text-black p-0'
                    >#{order.data.id}</Button>
                  </Col>
                </Row>
                <Row className='my-1'>
                  <Col xs={6} className='fw-bold'>Date</Col>
                  <Col xs={6}>
                    {order.getDate()}
                  </Col>
                </Row>
                <Row className='my-1'>
                  <Col xs={6} className='fw-bold'>Status</Col>
                  <Col xs={6}>{order.data.status}</Col>
                </Row>
                <Row className='my-1'>
                  <Col xs={6} className='fw-bold'>Delivery Date</Col>
                  <Col xs={6}>{order.getDeliveryDate()}</Col>
                </Row>
                <Row className='my-1'>
                  <Col xs={6} className='fw-bold'>Total</Col>
                  <Col xs={6}>
                    <span className='fw-bold'>${order.data.total}</span> for&nbsp;
                    {order.getTotalItems()} items
                  </Col>
                </Row>
                <Row className='my-1'>
                  <Col xs={6} className='my-1 fw-bold'>Actions</Col>
                  <Col xs={6} className='vstack gap-1'>
                    <OrderAgainButton order={order} />
                    {order.isModifiable() && <ChangeDeliveryDate order={order} />}
                    {order.isRefundable() && <RefundOrder order={order} handleRefetch={handleRefetch} />}
                  </Col>
                </Row>
              </Card>

            )
          })}
        </>}
      <div className='d-flex justify-content-center align-items-center'>
        {pages_loaded < total_pages &&
          <Button
            onClick={loadMoreOrders}
            disabled={isLoading}
            variant='success'
            className='text-white'
          >
            {isLoading ?
              <>
                Loading ... &nbsp;
                <Spinner
                  animation="border"
                  as="span"
                  size="sm"
                />
              </>
              : 'Load more'}
          </Button>
        }
      </div>
    </div>
  );
}