import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormikErrors, useFormik } from 'formik';
import {
  Alert,
  Button,
  Form,
  Spinner,
  Tabs,
  Tab,
  //Row,
  //Col
} from 'react-bootstrap';
import UserAPI from '../../API/userAPI';
import { setAuthInfo } from './userSlice';
import { loadCart, selectCartCoupon, selectCartItems, setCartCoupon, setCartItem } from '../cart/cartSlice';
import { loadAffiliate, loadLogoUrl, resetReferrals, selectAffiliate, setEmbedded } from '../referrals/referralsSlice';
import { clearDietaryRestrictions, selectProducts } from '../products/productsSlice';
import { getCouponByCode } from '../../API/coupons';
import CouponObj from '../coupons/couponObj';
import CartObj from '../cart/cartObj';
import { selectCustomer } from '../customer/customerSlice';
import CustomerObj from '../customer/customerObj';
import AffiliateObj from '../referrals/AffiliateObj';
import { selectIsMobileRoute, selectVisitorID, setVisitorID } from '../mobile/mobileSlice';
import RegisterForm from './RegisterForm';
// import x from "../../assets/images/X.svg"
// import fb from "../../assets/images/Fb.svg"
import google from "../../assets/images/google.svg"
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import ReCAPTCHA from 'react-google-recaptcha';
interface Response {
  token: string;
  user_email: string;
  user_nicename: string;
  user_display_name: string;
  data: {
    status: number,
    message?: string,
  };
}

interface FormValues {
  loginEmail: string;
  loginPassword: string;
  loginRememberMe: string;
}

export default function LoginForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [params] = useSearchParams();
  const successMsg = params.get('successMsg');
  const cartItems = useSelector(selectCartItems);
  const cartCoupon = useSelector(selectCartCoupon);
  const cart = new CartObj(useSelector(selectCartItems));
  const products = useSelector(selectProducts);
  const customer = new CustomerObj(useSelector(selectCustomer));
  const affiliateData = useSelector(selectAffiliate);
  const affiliateVisitorID = useSelector(selectVisitorID);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const affiliateSavedData = new AffiliateObj(affiliateData);
  const affiliateMeta = affiliateSavedData?.data;
  const [key, setKey] = useState('login');
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const [recaptchaError, setRecaptchaError] = useState('');
  const isLoginPage = location.pathname.includes('my-account');

  useEffect(() => {
    if (location.state && location.state.Tabkey) {
      setKey(location.state.Tabkey);
    }
  }, [location.state]);

  const handleSelect = (k: string | null) => {
    if (k !== null) {
      setKey(k);
      navigate(`/my-account${urlParams}`, {
        state: { Tabkey: k },
      });
    }
  };


  const handleRememberMeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      formik.setFieldValue('loginRememberMe', '1');
    } else {
      formik.setFieldValue('loginRememberMe', '0');
    }
  }

  const getCouponCodeFromQuery = (token: any, email: any, couponCode: any) => {
    getCouponByCode(
      couponCode
    ).then((coupons: Array<Record<string, any>>) => {
      if (!coupons.length) {
        setErrorMsg('Coupon "' + couponCode + '" is invalid.');
        return;
      }
      let coupon = new CouponObj(coupons[0]);
      let validationResult = coupon.validate(email, customer, cart, products);
      if (validationResult.error) {
        setErrorMsg(validationResult.message);
        return;
      }
      dispatch(setCartCoupon({ token: token, coupon: coupons[0] }));
    }).catch((e) => {
      console.error(e);
      setErrorMsg('An unexpected error has occurred. Please try again later.');
    });
  }

  const validate = (values: FormValues) => {
    let errors: FormikErrors<FormValues> = {};
    if (!values.loginEmail) {
      errors.loginEmail = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.loginEmail)) {
      errors.loginEmail = "Invalid email address";
    }
    if (!values.loginPassword) {
      errors.loginPassword = "Required";
    }
    return errors;
  }

  const formik = useFormik({
    initialValues: {
      loginEmail: '',
      loginPassword: '',
      loginRememberMe: '0',
      recaptcha: ''
    },
    validate,
    onSubmit: values => {
      if (!recaptchaValue) {
        setRecaptchaError("Please complete the reCAPTCHA.");
        return;
      }
      setErrorMsg('');
      setLoggingIn(true);
      UserAPI.authenticateUser(
        values.loginEmail,
        values.loginPassword,
        values.loginRememberMe === '1'
      ).then(async (response: Response) => {
        if ('data' in response && response.data.status === 403) {
          setErrorMsg("Invalid email/password combination.");
          setLoggingIn(false);
        } else if ('code' in response) {
          setErrorMsg("An error has occurred. Please try again later.");
          setLoggingIn(false);
        } else {
          if (values.loginRememberMe === '1') {
            try {
              localStorage.setItem('token', response.token);
            } catch (e) {
              console.error("Error adding token to local storage:", e);
            }
          }
          const setLoginInfo = {
            token: response.token,
            email: response.user_email,
            nicename: response.user_nicename,
            display_name: response.user_display_name,
            isRememberMe: values.loginRememberMe === '1',
            dateStored: new Date()
          }
          localStorage.setItem('setAuthInfo', JSON.stringify(setLoginInfo));
          dispatch(clearDietaryRestrictions());
          dispatch(setAuthInfo({
            token: response.token,
            email: response.user_email,
            nicename: response.user_nicename,
            display_name: response.user_display_name
          }));
          dispatch(resetReferrals())
          if (Object.entries(cartItems).length > 0) {
            // eslint-disable-next-line array-callback-return
            Object.entries(cartItems).map((tempItems: any, i) => {
              dispatch(
                setCartItem({
                  token: response.token,
                  cart_item: {
                    product_id: tempItems[1]?.product_id,
                    product_qty: tempItems[1]?.product_qty,
                    product_price: tempItems[1]?.product_price,
                  },
                })
              );
            });
          }
          await dispatch(loadCart(response.token));
          getCouponCodeFromQuery(response.token, response.user_email, cartCoupon?.code);
        }
      }).catch((e) => {
        setErrorMsg(e.message);
      }).finally(() => {

        if ((affiliateMeta?.meta?.custom_slug?.length !== undefined || affiliateMeta?.meta?.custom_slug?.length > 0) &&
          (affiliateMeta.affiliate_id !== undefined || affiliateMeta.affiliate_id !== null)) {

          dispatch(loadLogoUrl(affiliateSavedData.getCustomSlug()));
          dispatch(setEmbedded(true));
          if (affiliateVisitorID) {
            dispatch(setVisitorID(`${affiliateVisitorID}`))
          }
          if (affiliateMeta.affiliate_id) {
            dispatch(loadAffiliate(affiliateMeta.affiliate_id));
          }
        }
        setLoggingIn(false);
      });
    }
  });

  const login = useGoogleLogin({
    onSuccess: (codeResponse: any) => {
      handleGetUser(codeResponse);
    },
    onError: (error: any) => console.log("Login Failed:", error),
    scope: 'profile email'  // Request email and profile scopes
  });

  const handleGetUser = async (user: any) => {
    const data = user.access_token
    await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data}`,
      {
        headers: {
          Authorization: `Bearer ${data}`,
          Accept: "application/json",
        },
      }
    ).then((res) => {
      handleGoogleResolve(res.data, data);
    }).catch((err) => console.log(err));
  }

  const handleGoogleResolve = async (userData: any, accessToken: any) => {
    setLoggingIn(true);
    await UserAPI.socialLogin(
      userData.email,
      userData.family_name,
      userData.given_name
    ).then(async (response) => {
      if ('data' in response && response.data.status === 403) {
        setErrorMsg("Invalid email/password combination.");
        setLoggingIn(false);
      } else if ('code' in response) {
        setErrorMsg("An error has occurred. Please try again later.");
        setLoggingIn(false);
      } else {
        const setLoginInfo = {
          token: response.token,
          email: response.user_email,
          nicename: response.user_nicename,
          display_name: response.user_display_name,
          isRememberMe: formik?.values.loginRememberMe === '1',
          dateStored: new Date()
        }
        localStorage.setItem('token', response.token);
        localStorage.setItem('setAuthInfo', JSON.stringify(setLoginInfo));
        dispatch(clearDietaryRestrictions());
        dispatch(setAuthInfo({
          token: response.token,
          email: response.user_email,
          nicename: response.user_nicename,
          display_name: response.user_display_name
        }));
        dispatch(resetReferrals())
        if (Object.entries(cartItems).length > 0) {
          // eslint-disable-next-line array-callback-return
          Object.entries(cartItems).map((tempItems: any, i) => {
            dispatch(
              setCartItem({
                token: response.token,
                cart_item: {
                  product_id: tempItems[1]?.product_id,
                  product_qty: tempItems[1]?.product_qty,
                  product_price: tempItems[1]?.product_price,
                },
              })
            );
          });
        }
        await dispatch(loadCart(response.token));
        getCouponCodeFromQuery(response.token, response.user_email, cartCoupon?.code);
      }
    }).catch((e) => {
      setErrorMsg("An error has occurred. Please try again later.");
    }).finally(() => {

      if ((affiliateMeta?.meta?.custom_slug?.length !== undefined || affiliateMeta?.meta?.custom_slug?.length > 0) &&
        (affiliateMeta.affiliate_id !== undefined || affiliateMeta.affiliate_id !== null)) {

        dispatch(loadLogoUrl(affiliateSavedData.getCustomSlug()));
        dispatch(setEmbedded(true));
        if (affiliateVisitorID) {
          dispatch(setVisitorID(`${affiliateVisitorID}`))
        }
        if (affiliateMeta.affiliate_id) {
          dispatch(loadAffiliate(affiliateMeta.affiliate_id));
        }
      }

    });
  };

  const onReCAPTCHAChange = (value: string | null) => {
    setRecaptchaValue(value);
    if (value) {
      setRecaptchaError('');
    }
  };
  return (
    <>
      <Tabs activeKey={key} onSelect={handleSelect} className="mb-3">
        <Tab eventKey="login" title="Login">
          <Form className='login-form' onSubmit={formik.handleSubmit}>
            {successMsg &&
              <Alert className='mb-4' variant='success'>{successMsg}</Alert>
            }
            {errorMsg && <p className='text-danger'>{errorMsg}</p>}
            <Form.Group className="mb-3 form-group required">
              <Form.Control
                placeholder='Email address'
                id="loginEmail"
                type="email"
                size="lg"
                isValid={Boolean(formik.values.loginEmail) &&
                  !Boolean(formik.errors.loginEmail)}
                isInvalid={Boolean(formik.errors.loginEmail)}
                value={formik.values.loginEmail}
                onChange={formik.handleChange}
              />
              {formik.errors.loginEmail &&
                <Form.Control.Feedback type="invalid">
                  {formik.errors.loginEmail}
                </Form.Control.Feedback>}
            </Form.Group>
            <Form.Group className="mb-3 form-group required">
              <Form.Control
                placeholder='Password'
                id="loginPassword"
                type="password"
                size="lg"
                isValid={Boolean(formik.values.loginPassword) &&
                  !Boolean(formik.errors.loginPassword)}
                isInvalid={Boolean(formik.errors.loginPassword)}
                value={formik.values.loginPassword}
                onChange={formik.handleChange}
              />
              {formik.errors.loginPassword &&
                <Form.Control.Feedback type="invalid">
                  {formik.errors.loginPassword}
                </Form.Control.Feedback>}
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label
                className={`d-inline-flex align-items-center ${isLoginPage ? 'text-white' : 'text-black'}`}
              >
                <Form.Check
                  className='d-inline me-2 '
                  id="loginRememberMe"
                  value={formik.values.loginRememberMe}
                  onChange={handleRememberMeChange}
                />
                Remember me</Form.Label>
            </Form.Group>
            <span className={`${isLoginPage ? 'text-white' : 'text-black'} fs-12px`}>
              By submitting your email, you agree to our &nbsp;
              <Link to={`/terms-and-conditions${urlParams}`} className={`${isLoginPage ? 'text-white' : 'text-black'} fw-bold`}>Terms</Link> and <Link to={`/terms-and-conditions#privacy${urlParams}`} className={`${isLoginPage ? 'text-white' : 'text-black'} fw-bold`}>Privacy Policy</Link> to receive email correspondence from us.</span>
            <div className="mb-3 pt-3">
              <ReCAPTCHA
                sitekey="6LcqKu8pAAAAAMvOF2uKTXCUqNAAdzm-AMroLVv8"
                onChange={onReCAPTCHAChange}
              />
              {recaptchaError && <div className="text-danger">{recaptchaError}</div>}
            </div>

            <Button className='bg-white login-btn text-black w-100 mt-3' size="lg" type="submit" disabled={loggingIn}>
              {loggingIn ?
                <>
                  Please wait ...&nbsp;&nbsp;
                  <Spinner animation="border" as="span" size="sm" />
                </> : 'Login'
              }
            </Button>
          </Form>
        </Tab>
        <Tab eventKey="register" title="Register">
          <RegisterForm loggingIn={loggingIn} />
        </Tab>
      </Tabs>
      <div>
        <div className='text-center mt-4 text-white'>
          Or
        </div>
        <div className="text-center mt-4">
          <Button variant="white" disabled={loggingIn} className="google-btn w-100 mb-3 border border-primary bg-white text-black"
            onClick={() => login()}>
            <img src={google} alt="Google logo" style={{ height: '30px', marginRight: '10px' }} />
            Continue with Google
          </Button>
          {/* <Row className='btn-group-login'>
            <Col>
              <Button className="w-100 btn-primary" disabled={loggingIn}>
                <img src={fb} alt="Facebook logo" style={{ height: '30px', marginRight: '10px' }} />
                Login with Facebook</Button>
            </Col>
            <Col>

              <Button className="w-100 bg-black text-white" disabled={loggingIn}>
                <img src={x} alt="Twitter logo" style={{ height: '30px', marginRight: '10px' }} />
                Login with Twitter
              </Button>
            </Col>
          </Row> */}
          <div>
            <Link
              to={`/my-account/lost-password${urlParams}`}
              className='text-white d-block my-4 forgot-link'
            >
              Forgot Password?
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}