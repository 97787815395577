import React, { useEffect } from 'react'
import ElementorPage from '../ElementorPage';
import { useLocation } from 'react-router-dom';

export default function NutritionTeam() {
    const location = useLocation();

    useEffect(() => {
      const addCssLink = (cssUrl: string) => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = cssUrl;
        document.head.appendChild(link);
        return link;
      };
  
      const removeCssLink = (link: Element | null) => {
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
      };
  
      if (location.pathname.includes('/nutrition')) {
        let cssUrl;
        if (process.env.REACT_APP_BLOG_URL === 'https://blog.mightymeals.com') {
          cssUrl = `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-647745.css`;
        } else {
          cssUrl = `${process.env.REACT_APP_BLOG_URL}/wp-content/uploads/elementor/css/post-649246.css`;
        }
  
        const cssUrlWithCacheBust = `${cssUrl}?v=${new Date().getTime()}`;
  
        const link = addCssLink(cssUrlWithCacheBust);
  
        return () => {
          removeCssLink(link);
        };
      } else {
        let link;
        if (process.env.REACT_APP_BLOG_URL === 'https://blog.mightymeals.com') {
          link = document.querySelector('link[href*="post-647745.css"]');
        } else {
          link = document.querySelector('link[href*="post-649246.css"]');
        }
        removeCssLink(link);
      }
    }, [location.pathname]);
    
    return (
        <>
          {process.env.REACT_APP_BLOG_URL === 'https://blog.mightymeals.com' ? (
            <ElementorPage pageNo={647745} />
          ) : (
            <ElementorPage pageNo={649246} />
          )}
        </>
      )
}
