import { useFormikContext } from 'formik';
import { FormValues } from './interfaces';
import { Card, Form, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { loadDeliveryInfo, selectDeliveryData, selectDeliveryLoaded } from '../../features/delivery/deliverySlice';
import DeliveryObj from '../../features/delivery/deliveryObj';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { selectOrders } from '../../features/orders/ordersSlice';
import OrderObj from '../../features/orders/orderObj';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { selectToken } from '../../features/user/userSlice';
import { selectShippingMethods } from '../../features/shipping/shippingSlice';
import ShippingMethodObj from '../../features/shipping/shippingMethodObj';
import validateForm from './validateForm';
import { selectCartDeliveryDate, selectCartItems } from '../../features/cart/cartSlice';
import CartObj from '../../features/cart/cartObj';
import { selectProducts } from '../../features/products/productsSlice';
import { selectGeneralOptions } from '../../features/mobile/mobileSlice';
import { useLocation } from 'react-router-dom';


export default function DeliveryDateInput() {
  const location = useLocation();
  const token = useSelector(selectToken);
  const formik = useFormikContext<FormValues>();
  const deliveryLoaded = useSelector(selectDeliveryLoaded);
  const cartDeliveryDate = useSelector(selectCartDeliveryDate);
  const deliveryInfo = new DeliveryObj(useSelector(selectDeliveryData));
  const products = useSelector(selectProducts);
  const orders = useSelector(selectOrders);
  const cartItems = useSelector(selectCartItems);
  const [isProcessingOrders, setIsProcessingOrders] = useState(false);
  const [isdeliveryLoaded, setIsdeliveryLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const shippingMethods = useSelector(selectShippingMethods);
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const paymentOption = getGeneralOptions?.payment_option;
  const creditCardProcessor = paymentOption ? paymentOption : process.env.REACT_APP_CREDIT_CARD_PROCESSOR;
  const [shippingMethodID, setShippingMethodID] = useState<number | null>(null);
  const [shippingMethodByDate, setShippingMethodByDate] = useState<ShippingMethodObj | null>(null);
  const cart = useMemo(() => {
    return new CartObj(cartItems)
  }, [cartItems]);
  const zip = formik.values.use_shipping ? formik.values.shipping_postcode :
    formik.values.billing_postcode;
  const city = formik.values.use_shipping ? formik.values.shipping_city :
    formik.values.billing_city;

  const handleChange = (date: Date | null) => {
    if (date !== null) {
      formik.setFieldValue('delivery_date', date);
    }
  };

  useEffect(() => {
    if (location.state !== null && location.state.date) {
      formik.setFieldValue('delivery_date', new Date(location.state.date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    if (formik.values.delivery_date) {
      handleCheckDeliveryisAvailable(new Date(formik.values.delivery_date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [city, formik.values.delivery_date, shippingMethodByDate?.data.title, formik.values.use_shipping])

  useEffect(() => {
    if (cartDeliveryDate && (formik.values.delivery_date === undefined || formik.values.delivery_date === null)) {
      formik.setFieldValue('delivery_date', new Date(cartDeliveryDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartDeliveryDate, formik.values.delivery_date])

  useEffect(() => {
    setShippingMethodID(formik.values.shipping_method ?? null);
  }, [formik.values.shipping_method])

  useEffect(() => {
    const shippingByDaTte = ShippingMethodObj.getById(shippingMethods, Number(shippingMethodID));
    if (shippingByDaTte) {
      isValidate(shippingByDaTte);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingMethodID, shippingMethods])

  useEffect(() => {
    const shippingMethodInputs = document.getElementsByName('shipping-method');
    if (shippingMethodInputs.length > 0) {
      const firstShippingMethodValue = (shippingMethodInputs[0] as HTMLInputElement)?.value;

      if (firstShippingMethodValue) {
        if (shippingMethodID !== Number(firstShippingMethodValue)) {
          formik.setFieldValue('shipping_method', Number(firstShippingMethodValue))
          setShippingMethodID(Number(firstShippingMethodValue));
        }
      } else {
        console.log("Value is empty.");
      }
    } else {
      console.log("No input elements with the name 'shipping-method' found.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.shipping_postcode, formik.values.billing_postcode])

  useEffect(() => {
    if (!deliveryLoaded) {
      setIsdeliveryLoaded(false);
      loadDeliveryInfo();
    }
    else {
      setIsdeliveryLoaded(true);
    }
  }, [deliveryLoaded])

  const handleCheckDeliveryisAvailable = (date: any) => {
    const cityName = city ? city : '';
    let isNextWednesdayEnabled = false;
    if (shippingMethodByDate?.data.ups_delivery_method === true) {
      const blockedDates = deliveryInfo.getBlockedDates(cityName, zip, shippingMethodByDate);
      const isDisabledTuesday = blockedDates.filter((blockedDate: any) => {
        const blockedDay = new Date(blockedDate).getDay();
        return blockedDay === 2;
      });

      if (isDisabledTuesday.length > 0) {

        isDisabledTuesday.forEach((tuesday: any) => {
          const disabledTuesday = new Date(tuesday);
          const nextWednesday = new Date(tuesday);
          nextWednesday.setDate(disabledTuesday.getDate() + 1);

          if (date.toDateString() === nextWednesday.toDateString()) {
            isNextWednesdayEnabled = true;
          }
        });
      }
    }
    setErrorMessage('');
    const zipCode = formik.values.use_shipping ? formik.values.shipping_postcode : formik.values.billing_postcode;
    if (!isNextWednesdayEnabled && !deliveryInfo.dateIsAvailable(new Date(date), cityName, zipCode, shippingMethodByDate)) {
      setErrorMessage('That date is unavailable, Please choose another.');
    } else {
      setErrorMessage('');
    }
  }

  const getDayClassNames = (date: Date) => {
    const dateString = format(date, 'EEE, MMM d');
    const processingOrders = orders.filter((order: any) => {
      const orderObj = new OrderObj(order);
      return orderObj.getDeliveryDate() === dateString && orderObj.data.status === 'processing';
    });

    if (processingOrders.length > 0) {
      return 'delivery-processing';
    }

    return '';
  };

  const isDeliveryDay = (date: Date) => {
    const today = new Date();
    const blockedDates = deliveryInfo.getBlockedDates(city, zip, shippingMethodByDate);

    if (shippingMethodByDate?.data.ups_delivery_method === true) {
      const isDisabledTuesday = blockedDates.filter((blockedDate: any) => {
        const blockedDay = new Date(blockedDate).getDay();
        return blockedDay === 2;
      });

      if (isDisabledTuesday.length > 0) {
        let isNextWednesdayEnabled = false;

        isDisabledTuesday.forEach((tuesday: any) => {
          const disabledTuesday = new Date(tuesday);
          const nextWednesday = new Date(tuesday);
          nextWednesday.setDate(disabledTuesday.getDate() + 1);

          if (date.toDateString() === nextWednesday.toDateString()) {
            isNextWednesdayEnabled = true;
          }
        });

        if (isNextWednesdayEnabled) {
          const nextWednesday = new Date(today);
          nextWednesday.setDate(today.getDate() + ((3 + 7 - today.getDay()) % 7));
          if (date.toDateString() === nextWednesday.toDateString()) {
            return false;
          }
          return true;
        }
      }

      if (today.getDay() === 2 || today.getDay() === 3) {
        const nextThursday = new Date(today);
        const nextFriday = new Date(today);
        nextThursday.setDate(today.getDate() + ((4 + 7 - today.getDay()) % 7));
        nextFriday.setDate(today.getDate() + ((5 + 7 - today.getDay()) % 7));
        if (date.toDateString() === nextThursday.toDateString() || date.toDateString() === nextFriday.toDateString()) {
          return false;
        }
      } else if (today.getDay() > 4 || today.getDay() === 1 || today.getDay() === 0) {
        const nextWednesday = new Date(today);
        const nextTuesday = new Date(today);
        nextTuesday.setDate(today.getDate() + ((2 + 7 - today.getDay()) % 7));
        nextWednesday.setDate(today.getDate() + ((3 + 7 - today.getDay()) % 7));
        if (date.toDateString() === nextTuesday.toDateString()) {
          return false;
        }
        if (date.toDateString() === nextWednesday.toDateString()) {
          return false;
        }
      }

      return deliveryInfo.getDeliveryDays(city, zip, shippingMethodByDate).includes(date.getDay());
    } else {
      return deliveryInfo.getDeliveryDays(city, zip, shippingMethodByDate).includes(date.getDay());
    }
  }

  const isValidate = async (shippingByDaTte: any) => {
    const isUPSOrUPSFreeShipping = shippingMethodByDate?.data.ups_delivery_method === true;
    await validateForm(creditCardProcessor, formik.values, token, deliveryInfo, cart, products, isUPSOrUPSFreeShipping);
    setShippingMethodByDate(shippingByDaTte);
  }

  return (
    <Card className={`delivery-date-input mb-3 ${!isdeliveryLoaded ? 'text-center' : ''}`} body>
      {!isdeliveryLoaded ? (
        <Spinner
          animation="border"
          className='text-dark'
        />
      ) :
        (<>
          <div className='my-4 fs-3'>Choose Your Delivery Date:</div>
          <div className='my-3 fs-5'>
            Only available dates are shown, dates not shown are at capacity.
            {isProcessingOrders &&
              <span className='fs-16px d-flex align-items-center'>
                <div className="green-boxes me-2"></div>
                Indicates your current scheduled orders
              </span>}
          </div>
          <Form.Group className="form-group required">
            <DatePicker
              autoComplete="date"
              calendarStartDay={1}
              filterDate={isDeliveryDay}
              excludeDates={deliveryInfo.getBlockedDates(city, zip, shippingMethodByDate)}
              selected={formik.values.delivery_date ? new Date(formik.values.delivery_date) : null}
              minDate={deliveryInfo.getStartDate(zip)}
              maxDate={deliveryInfo.getEndDate()}
              onChange={date => { handleChange(date); setIsProcessingOrders(false) }}
              onFocus={e => { e.target.blur(); if (token) setIsProcessingOrders(true) }}
              disabledKeyboardNavigation
              dayClassName={getDayClassNames}
            />
            {formik?.errors?.delivery_date &&
              <div className='text-danger mt-1'>
                {formik?.errors?.delivery_date}
              </div>
            }
            {errorMessage &&
              <div id='errorMessagediv' className='text-danger mt-1'>
                {errorMessage}
              </div>
            }
          </Form.Group>
        </>)}
    </Card>
  );
}