import { useState } from "react";
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { selectUpdatingOrder, setUpdatingOrder, updateOrder } from "./ordersSlice";
import OrderObj from "./orderObj";
import OrdersAPI from "../../API/ordersAPI";
import { selectToken } from "../user/userSlice";
import ToastError from '../../components/ToastError';
import { useNavigate } from "react-router-dom";
import { selectIsMobileRoute } from "../mobile/mobileSlice";

interface Props {
  order: OrderObj;
  handleRefetch?: () => void;
}

export default function RefundOrder({ order, handleRefetch }: Props) {
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const updatingOrder = useSelector(selectUpdatingOrder);
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';

  const handleRefund = async () => {
    setShowModal(false);
    setErrorMsg('');
    setRefunding(true);
    dispatch(setUpdatingOrder(true));

    try {
      const response = await OrdersAPI.refundOrder(token, order.data.id, order.getAuthNetTransId());

      if ('code' in response) {
        setErrorMsg(response.message);
      } else if ('id' in response) {
        dispatch(updateOrder(response));
      }
      
    } catch (error: any) {
      setErrorMsg(error.message);
    } finally {
      setRefunding(false);
      dispatch(setUpdatingOrder(false));
      navigate(`/my-account${urlParams}`);
      if (handleRefetch) {
        handleRefetch();
      }
    }
  };


  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Really? Cancel/refund this order?</p>
          <div className='d-flex justify-content-end gap-2'>
            <Button variant="outline-dark" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="dark" onClick={handleRefund}>
              Refund Order
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {errorMsg &&
        <ToastError
          msg={errorMsg}
          onClose={() => setErrorMsg('')}
          position="top-center"
        />
      }

      {refunding ?
        <Button
          className='bg-red border-red'
          size='sm'
          disabled
        >
          <Spinner animation="border" as="span" size="sm" />
          &nbsp;&nbsp;Refunding ...
        </Button> :
        <Button
          className='bg-red border-red'
          size="sm"
          onClick={() => setShowModal(true)}
          disabled={updatingOrder}
        >
          Refund / Cancel
        </Button>
      }
    </>
  )
}