export interface AuthResponse {
  token: string;
  user_email: string;
  user_nicename: string;
  user_display_name: string;
  data: { status: number };
}

export interface RegResponse {
  id?: number;
  data?: { status: number };
}

export default class UserAPI {
  static async authenticateUser(
    username: string,
    password: string,
    remember_me: boolean
  ) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/sign_in',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        credentials: 'include',
        body: JSON.stringify({
          username: username,
          password: password,
          remember_me: false
        })
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async getUserSession(token: string) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/session?token=' + token,
      {
        method: 'GET',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async logoutUser(token: string) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/sign_out?token=' + token,
      {
        method: 'DELETE',
        headers: { 'content-type': 'application/json;charset=UTF-8' }
      }
    ));
    return await response.json();
  }

  static async registerUser(
    email: string,
    password: string,
    metaData: Array<Record<string, any>> | null = null
  ) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/sign_up',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          username: email,
          email: email,
          password: password,
          ...(metaData !== null && { meta_data: metaData })
        })
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async resetPassword(email: string) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/password/reset',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          email: email
        })
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async setPassword(email: string, password: string, code: string) {
    let response = await (fetch(
      process.env.REACT_APP_SERVER_URL + '/password/set',
      {
        method: 'POST',
        headers: { 'content-type': 'application/json;charset=UTF-8' },
        body: JSON.stringify({
          email: email,
          password: password,
          code: code
        })
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

  static async socialLogin(email: string, familyName: string, givenName: string) {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/social_login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email: email,
          family_name: familyName,
          given_name: givenName
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error. status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  }

  static async getIPConfigs() {
    let response = await (fetch(
      `https://ipinfo.io/json?token=ff8e0df20bdd3f`,
      {
        method: 'GET'
      }
    ));
    if (!response.ok) {
      throw new Error(`HTTP error. status: ${response.status}`);
    }
    return await response.json();
  }

}