import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import metaData from './metaData';
import { useSelector } from 'react-redux';
import { selectToken } from '../features/user/userSlice';

export default function MetaPage() {
    const location = useLocation();
    const { pathname } = location;
    const token = useSelector(selectToken);
    const normalizedPath = pathname.split('?')[0].replace(/\/$/, '');
    const isLoginPage = location.state?.Tabkey === 'login';
    const isRegisterPage = location.state?.Tabkey === 'register';

    const getCurrentMeta = () => {
        if (!token) {
            if (isLoginPage) return metaData['/my-account-login'];
            if (isRegisterPage) return metaData['/my-account-register'];
        }
        return metaData[normalizedPath] || metaData['/'];
    };

    const currentMeta = getCurrentMeta();

    useEffect(() => {
        const metaTags = document.querySelectorAll(`
            meta[name="description"],
            meta[property="og:title"],
            meta[property="og:description"],
            meta[property="og:image"],
            meta[property="og:image:width"],
            meta[property="og:image:height"]
        `);
        metaTags.forEach(tag => tag.remove());

        const titleTag = document.querySelector('title');
        if (titleTag) {
            titleTag.remove();
        }
    }, []);

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{currentMeta.title}</title>
            <meta property="og:title" content={currentMeta.title} />
            <meta name="description" content={currentMeta.description} />
            <meta property="og:description" content={currentMeta.description} />
            <meta property="og:image" content={currentMeta.image} />
            <meta property="og:image:width" content="2560" />
            <meta property="og:image:height" content="1709" />
        </Helmet>
    );
}