import CartObj from "../cart/cartObj";
import Helper from "../../utils/Helper";

export default class ShippingMethodObj {
  data: Record<string, any>;

  constructor(data: Record<string, any>) {
    this.data = data;
  }

  getLabel = () => {
    if (!this.data.cost || this.data.cost === "0") return this.data.title;

    return (
      <div>
        {this.data.title}:&nbsp;
        {<span className='fw-normal'>{
          Helper.formattedCurrency(this.data.cost)
        }</span>}
      </div>
    );
  }
  isEmailDelivery = () => {
    return this.data.ID === 51170;
  }

  isZipCodeMatch = (zipcode: string) => {
    for (const conditionGroup of this.data.condition_groups) {
      for (const condition of conditionGroup) {
        if (condition.condition === 'zipcode') {
          if (ShippingMethodObj.isZipCodeMatch(zipcode,
            condition.operator, condition.value)) return true;
        }
      }
    }
    return false;
  }

  isMatch = (
    zipcode: string | undefined,
    cart: CartObj,
    products: Array<Record<string, any>>,
    coupon: Record<string, any> | null,
    customer: Record<string, any>,
    isUtmThreshold: boolean,
    thresHoldPrice: string | undefined,
  ) => {
    for (const conditionGroup of this.data.condition_groups) {
      if (
        this.#conditionGroupIsMatch(conditionGroup, zipcode, cart,
          products, coupon, customer, isUtmThreshold, thresHoldPrice)
      ) {
        return true;
      }
    }
    return false;
  }


  // -------------------------------------
  // P R I V A T E   M E T H O D S
  // -------------------------------------

  #conditionGroupIsMatch = (
    conditionGroup: Array<Record<string, any>>,
    zipcode: string | undefined,
    cart: CartObj,
    products: Array<Record<string, any>>,
    coupon: Record<string, any> | null,
    customer: Record<string, any>,
    isUtmThreshold: boolean,
    thresHoldPrice: string | undefined,
  ) => {
    for (const condition of conditionGroup) {
      switch (condition.condition) {
        case 'category':
          if (!ShippingMethodObj.isCategoryMatch(cart, products,
            condition.operator, condition.value)) return false;
          break;
        case 'contains_product':
          if (!ShippingMethodObj.isProductMatch(cart, condition.operator,
            condition.value)) return false;
          break;
        case 'coupon':
          if (!ShippingMethodObj.isCouponMatch(coupon, condition.operator,
            condition.value)) return false;
          break;
        case 'role':
          if (!ShippingMethodObj.isRoleMatch(customer, condition.operator,
            condition.value)) return false;
          break;
        case 'subtotal':
          const effectiveThreshold = (isUtmThreshold && thresHoldPrice === "0") ? '1000000000000000000' : (isUtmThreshold === true ? thresHoldPrice : condition.value);
          if (!ShippingMethodObj.isSubtotalMatch(cart, condition.operator, effectiveThreshold)) {
            return false;
          }
          break;
        case 'zipcode':
          if (!ShippingMethodObj.isZipCodeMatch(zipcode,
            condition.operator, condition.value)) return false;
          break;
        default:
          console.error("Unknown condition: ", condition.condition);
          return false;
      }
    }
    return true;
  }

  // -------------------------------------
  // S T A T I C   M E T H O D S
  // -------------------------------------

  static getById = (
    shippingMethods: Array<Record<string, any>>,
    id: number
  ) => {
    const smData: Record<string, any> | undefined = shippingMethods.find((sm) => {
      return sm.ID === id;
    });

    if (smData) return new ShippingMethodObj(smData);

    return null;
  }

  static getShippingMethodCost = (
    id: undefined | number,
    shippingMethods: Array<Record<string, any>>
  ) => {
    if (!id) return 0;

    const sm = this.getById(shippingMethods, id);
    return sm ? parseFloat(sm.data.cost) : 0;
  }

  static isCategoryMatch = (
    cart: CartObj,
    products: Array<Record<string, any>>,
    operator: string,
    value: string
  ) => {
    let match = true;
    if (operator === '==') {
      if (cart.hasProductWithoutCategory(value, products)) match = false;
    }
    if (operator === '!=') {
      if (cart.hasProductWithCategory(value, products)) match = false;
    }
    return match;
  }

  static isCouponMatch = (
    coupon: Record<string, any> | null,
    operator: string,
    value: string
  ) => {
    if (coupon) {
      if (operator === '==') return coupon.code === value;
      if (operator === '!=') return coupon.code !== value;
    }
    return false;
  }

  static isEmailDelivery = (id: number | undefined) => {
    return id === 51170;
  }

  static isProductMatch = (
    cart: CartObj,
    operator: string,
    value: string
  ) => {
    if (operator === '==') {
      if (cart.hasProductWithID(value)) return true;
    }
    if (operator === '!=') {
      if (!cart.hasProductWithID(value)) return true;
    }
    return false;
  }


  static isRoleMatch = (
    customer: Record<string, any>,
    operator: string,
    value: string
  ) => {
    if (!('capabilities' in customer)) return false;

    if (operator === '==') return value in customer.capabilities;
    if (operator === '!=') return !(value in customer.capabilities);

    return false;
  }


  static isSubtotalMatch = (
    cart: CartObj,
    operator: string,
    value: string
  ) => {
    let subtotal = cart.getSubtotal(null, null, null) as number;

    switch (operator) {
      case '==':
        return subtotal === parseInt(value);
      case '!=':
        return subtotal !== parseInt(value);
      case '>=':
        return subtotal >= parseInt(value);
      case '<=':
        return subtotal <= parseInt(value);
      default:
        console.error("Unknown operator: ", operator);
        return false;
    }
  }

  static isZipCodeMatch = (
    zipcode: string | undefined,
    operator: string,
    value: string
  ) => {
    if (!zipcode) return false;

    let match;
    let zipValues = value.split(/,+ */);

    if (operator === '==') {
      for (let zipValue of zipValues) {
        zipcode = zipcode.replace(/[^0-9a-zA-Z]/, '');
        zipValue = zipValue.split('*').join('');
        if (zipValue.length === 0) continue;

        let parts = zipValue.split('-');

        if (parts.length > 1) {
          match = parseInt(zipcode) >= Math.min(...parts.map((part) => parseInt(part))) &&
            parseInt(zipcode) <= Math.max(...parts.map((part) => parseInt(part)));
        } else {
          match = zipcode.match(new RegExp('^' + zipValue, 'i'));
        }

        if (match) return true;
      }
      return false;
    }
    if (operator === '!=') {
      for (let zipValue of zipValues) {
        zipcode = zipcode.replace(/[^0-9a-zA-Z]/, '');
        zipValue = zipValue.split('*').join('');
        if (zipValue.length === 0) continue;

        let parts = zipValue.split('-');

        if (parts.length > 1) {
          match = parseInt(zipcode) >= Math.min(...parts.map((part) => parseInt(part))) &&
            parseInt(zipcode) <= Math.max(...parts.map((part) => parseInt(part)));
        } else {
          match = zipcode.match(new RegExp('^' + zipValue, 'i'));
        }

        if (match) return false;
      }
      return true;
    }

    if (operator === '>=') return parseInt(zipcode) >= parseInt(value);

    if (operator === '<=') return parseInt(zipcode) <= parseInt(value);

    return false;
  }
}