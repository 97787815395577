import { Link } from "react-router-dom"
import { Accordion, Card } from "react-bootstrap"
import { selectIsMobileRoute } from "../mobile/mobileSlice";
import { useSelector } from "react-redux";

export default function ProductFAQs() {
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  
  return (
    <div className='product-faqs my-5'>
      <div className='faq-header text-center mb-4'>Frequently Asked Questions</div>
      <Card>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Where do you source your food?</Accordion.Header>
            <Accordion.Body>
              We pride ourselves in serving you top-quality ingredients! We use sustainably 
              sourced seafood, hormone and antibiotic free poultry, along with grass-fed beef 
              whenever possible. We also use local &amp; organic vegetables when available.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Are your meals freshly prepared or frozen?</Accordion.Header>
            <Accordion.Body>
              Our prepared meals are made &amp; delivered fresh. They are never frozen.            
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              How do I know if a meal or food item is free from my food allergies or 
              sensitivities?
            </Accordion.Header>
            <Accordion.Body>
              We have search filters for that, of course! You can order meals minus your 
              food allergies, preferences, or sensitivities easily.&nbsp;
              <Link to={`/order${urlParams}`}>Go to our menu and use the convenient filters</Link>&nbsp;
              to search for dairy-free, gluten-free, not spicy, nut-free, shellfish-free, 
              soy-free, and wheat-free meals, snacks, and à la carte food items.             
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              How long do the meals need to be heated for?
            </Accordion.Header>
            <Accordion.Body>
              The instructions and time to reheat are labeled on each meal. The average 
              heating time for our meals is 1-2 minutes. However, heating times can vary 
              depending on your microwave wattage/power and temperature preference.              
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              How long do your meals last?
            </Accordion.Header>
            <Accordion.Body>
              Meals stay fresh for about 5 days after the “Made Fresh On” date labeled on 
              your meal and can be frozen for up to 3 weeks. However, we think they are 
              best enjoyed fresh!               
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>Can I freeze my meals?</Accordion.Header>
            <Accordion.Body>
              Yes! Meals can be frozen for up to 3 weeks (except for salads, we’re not that 
              silly!). We recommend thawing them overnight in the fridge before heating them 
              according to the instructions, and eating them.                
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="6">
            <Accordion.Header>Can I make modifications to my meals?</Accordion.Header>
            <Accordion.Body>
              At this time, our meals are made ‘as is’ with no modifications or substitutions 
              allowed. For custom meals, please consider ordering our bulk and à la carte 
              food options.                
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="7">
            <Accordion.Header>Are there any disclaimers?</Accordion.Header>
            <Accordion.Body>
              The nutritional information for products at the time of their preparation is 
              reflected on the labels on our meal/product containers. MightyMeals does not 
              guarantee the accuracy of any nutritional information provided by MightyMeals. 
              MightyMeals will not be responsible for any loss or damage resulting from your 
              reliance on nutritional information, nor for ensuring that whatever foods you 
              purchase or consume are in accordance with your respective dietary needs, 
              restrictions or preferences. Please note that nutritional information on our site 
              reflects recent updates to meals based on evolving ingredients.<br /><br />

              *Consuming raw or undercooked meats, poultry, seafood, shellfish, or eggs may 
              increase your risk of food-borne illness.           
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
    </div>
  )
}