import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface MobileState {
  showCart: boolean;
  newCartItem: boolean;
  notifyItem: {
    productName: string;
    productPrice: number;
    productIMG: string;
  } | null;
  showProductFilters: boolean;
  refferalCode: string;
  refferalSMSBodyText: string;
  refferalBtnText: string;
  visitorID: string;
  refferalID: string;
  isGiftCardInCart: boolean;
  fortisTransactionID: string;
  isMobileNavToggled: boolean;
  isMobileRoute: string;
  lifeTimeAffiliate: Record<string, any>;
  generalOptions: Record<string, any>;
  savedCardData: Record<string, any>;
  isLoading: boolean;
  isLoadMore: boolean;
  utmParamsURL: string;
  utmThreshold: Record<string, any>;
  utmShipping: Record<string, any>;
  iPAddress: string;
}

const initialState: MobileState = {
  showCart: false,
  newCartItem: false,
  notifyItem: null,
  showProductFilters: false,
  refferalCode: '',
  refferalSMSBodyText: '',
  refferalBtnText: '',
  visitorID: '',
  refferalID: '',
  isGiftCardInCart: false,
  fortisTransactionID: '',
  isMobileNavToggled: false,
  isMobileRoute: '',
  lifeTimeAffiliate: {},
  generalOptions: {},
  savedCardData: {},
  isLoading: false,
  isLoadMore: false,
  utmParamsURL: '',
  utmThreshold: {},
  utmShipping: {},
  iPAddress: '',
}

export const mobileSlice = createSlice({
  name: "mobile",
  initialState: initialState,
  reducers: {
    setShowCart: (state, action) => {
      return {
        ...state,
        showCart: action.payload
      }
    },
    setNewCartItem: (state, action) => {
      return {
        ...state,
        newCartItem: action.payload
      }
    },
    setNotifyItem: (state, action) => {
      return {
        ...state,
        notifyItem: action.payload,
      };
    },
    setShowProductFilters: (state, action) => {
      return {
        ...state,
        showProductFilters: action.payload
      }
    },
    setRefferalCode: (state, action) => {
      return {
        ...state,
        refferalCode: action.payload
      }
    },
    setRefferalSMSBodyText: (state, action) => {
      return {
        ...state,
        refferalSMSBodyText: action.payload
      }
    },
    setRefferalBtnText: (state, action) => {
      return {
        ...state,
        refferalBtnText: action.payload
      }
    },
    setVisitorID: (state, action) => {
      return {
        ...state,
        visitorID: action.payload
      }
    },
    setRefferalID: (state, action) => {
      return {
        ...state,
        refferalID: action.payload
      }
    },
    setIsGiftCardInCart: (state, action) => {
      return {
        ...state,
        isGiftCardInCart: action.payload
      }
    },
    setFortisTransactionID: (state, action) => {
      return {
        ...state,
        fortisTransactionID: action.payload
      }
    },
    setIsMobileNavToggled: (state, action) => {
      return {
        ...state,
        isMobileNavToggled: action.payload
      }
    },
    setIsMobileRoute: (state, action) => {
      return {
        ...state,
        isMobileRoute: action.payload
      }
    },
    setLifeTimeAffiliate: (state, action) => {
      return {
        ...state,
        lifeTimeAffiliate: action.payload
      }
    },
    setGeneralOptions: (state, action) => {
      return {
        ...state,
        generalOptions: action.payload
      }
    },
    setSavedCardData: (state, action) => {
      return {
        ...state,
        savedCardData: action.payload
      }
    },
    setIsLoading: (state, action) => {
      return {
        ...state,
        isLoading: action.payload
      }
    },
    setIsLoadMore: (state, action) => {
      return {
        ...state,
        isLoadMore: action.payload
      }
    },
    setUtmParamsURL: (state, action) => {
      return {
        ...state,
        utmParamsURL: action.payload
      }
    },
    setUtmThreshold: (state, action) => {
      return {
        ...state,
        utmThreshold: action.payload
      }
    },
    setUtmShipping: (state, action) => {
      return {
        ...state,
        utmShipping: action.payload
      }
    },
    setIPAddress: (state, action) => {
      return {
        ...state,
        iPAddress: action.payload
      }
    },
  }
});

export const { setShowCart, setShowProductFilters, setNewCartItem, setNotifyItem, setRefferalCode, setRefferalSMSBodyText, 
                setRefferalBtnText, setVisitorID, setRefferalID, setIsGiftCardInCart, setFortisTransactionID, setIsMobileNavToggled, 
                setIsMobileRoute, setLifeTimeAffiliate, setGeneralOptions, setSavedCardData, setIsLoading, setIsLoadMore, 
                setUtmParamsURL, setUtmThreshold, setUtmShipping, setIPAddress } = mobileSlice.actions;
export const selectShowCart = (state: RootState ) => state.mobile.showCart;
export const selectNewCartItem = (state: RootState ) => state.mobile.newCartItem;
export const selectNotifyItem = (state: RootState) => state.mobile.notifyItem;
export const selectShowProductFilters = (state: RootState ) => state.mobile.showProductFilters;
export const selectRefferalCode = (state: RootState ) => state.mobile.refferalCode;
export const selectRefferalSMSBodyText = (state: RootState ) => state.mobile.refferalSMSBodyText;
export const selectRefferalBtnText = (state: RootState ) => state.mobile.refferalBtnText;
export const selectVisitorID = (state: RootState ) => state.mobile.visitorID;
export const selectRefferalID = (state: RootState ) => state.mobile.refferalID;
export const selectIsGiftCardInCart = (state: RootState ) => state.mobile.isGiftCardInCart;
export const selectFortisTransactionID = (state: RootState ) => state.mobile.fortisTransactionID;
export const selectIsMobileNavToggled = (state: RootState ) => state.mobile.isMobileNavToggled;
export const selectIsMobileRoute = (state: RootState ) => state.mobile.isMobileRoute;
export const selectLifeTimeAffiliate = (state: RootState ) => state.mobile.lifeTimeAffiliate;
export const selectGeneralOptions = (state: RootState ) => state.mobile.generalOptions;
export const selectSavedCardData = (state: RootState ) => state.mobile.savedCardData;
export const selectIsLoading = (state: RootState ) => state.mobile.isLoading;
export const selectIsLoadMore = (state: RootState ) => state.mobile.isLoadMore;
export const selectUtmParamsURL = (state: RootState ) => state.mobile.utmParamsURL;
export const selectUtmThreshold = (state: RootState ) => state.mobile.utmThreshold;
export const selectUtmShipping = (state: RootState ) => state.mobile.utmShipping;
export const selectIPAddress = (state: RootState ) => state.mobile.iPAddress;
export default mobileSlice.reducer;