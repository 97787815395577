import React, { useEffect, useState } from 'react'
import { Button, Container, Modal, Spinner, Image } from 'react-bootstrap';
import './core/FitDashboard.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import SwiperCore, { Navigation } from 'swiper';
import WorkOutPage from './FitPages/WorkOutPage';
import FilterPage from './Filters/FilterPage';
import { clearMightyFitFilter, loadMightyFit } from './core/filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { loadWorkouts } from './core/workoutSlice';
import { selectToken } from '../../features/user/userSlice';
import { loadWorkoutPlans, selectWorkPlansLoadError, selectWorkPlansLoaded, selectWorkPlansLoading } from './core/workoutPlansSlice';
import { selectOrders } from '../../features/orders/ordersSlice';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import MightyFitAPI from '../../API/mightyFitAPI';
import CustomerObj from '../../features/customer/customerObj';
import { selectCustomer } from '../../features/customer/customerSlice';
import { selectCustomerDetailsIsLoading, selectCustomerDetailsIsLoaded } from '../../features/customer/customerDetailSlice';
import thumbMightyFitRight from '../../assets/images/thumb-mighty-fit-right.png';
import thumbMightyFitLogo from '../../assets/images/thumb-mighty-fit-logo.png';
import thumbMightyFitLeft from '../../assets/images/thumb-mighty-fit-left.png';
import { selectIsMobileRoute } from '../../features/mobile/mobileSlice';

SwiperCore.use([Navigation]);

export default function FitDashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const workPlansLoaded = useSelector(selectWorkPlansLoaded);
  const workPlansLoadError = useSelector(selectWorkPlansLoadError);
  const workPlansLoading = useSelector(selectWorkPlansLoading);
  const orders: Array<Record<string, any>> = useSelector(selectOrders);
  const customerLoading = useSelector(selectCustomerDetailsIsLoading);
  const customerLoaded = useSelector(selectCustomerDetailsIsLoaded);
  const [isModalShow, setIsModalShow] = useState(false);
  const [isTokenization, setIsTokenization] = useState('');
  const customer = new CustomerObj(useSelector(selectCustomer));
  const customerEmail = customer?.data?.email;
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '/';

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://players.brightcove.net/6057949464001/s2ZHtIoZ0_default/index.min.js';
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await MightyFitAPI.getUsersAuthToken();
        if (response) {
          setIsTokenization(response.idToken);
        }
      } catch (error) {
        console.error(error);
      }
    }
    dispatch(clearMightyFitFilter());
    fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (orders.length > 0) {
      const lastOrderDate = moment(orders[orders.length - 1].date_paid).toDate();
      const twoMonthsAgo = moment().subtract(2, 'months').toDate();

      if (lastOrderDate < twoMonthsAgo) {
        setIsModalShow(true);
      }
    } else if (orders.length === 0) {
      setIsModalShow(true);
    }
  }, [orders]);

  useEffect(() => {
    if (!customerLoading && customerLoaded && orders.length > 0){
      const lastOrderDateLastLength = moment(orders[orders.length - 1].date_paid).toDate();
      const lastOrderDateFirstLength = moment(orders[0].date_paid).toDate();
      const twoMonthsAgo = moment().subtract(2, 'months').toDate();

      if (!(lastOrderDateLastLength < twoMonthsAgo) || !(lastOrderDateFirstLength < twoMonthsAgo)) {
        setIsModalShow(false);
      }
    }
  }, [customerLoading, customerLoaded, orders])

  useEffect(() => {
    dispatch(loadMightyFit());
    dispatch(loadWorkouts('mighty_fits?page=1'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchAPI() {
      try {
        const response = await MightyFitAPI.getUsersFITUsers(isTokenization);
        let invitationSent = false;
        if (response) {
          response.users.forEach((user: { email: any; }) => {
            if (user.email !== customerEmail && invitationSent === false) {
              MightyFitAPI.postUserInvitation(customerEmail, isTokenization);
              invitationSent = true;
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (customerEmail && isTokenization) {
      fetchAPI();
    }
  }, [customerEmail, isTokenization]);

  useEffect(() => {
    if (token && !workPlansLoaded && !workPlansLoadError && !workPlansLoading) {
      dispatch(loadWorkoutPlans(token));
    }
  }, [token, workPlansLoaded, workPlansLoadError, workPlansLoading, dispatch]);

  return (
    <Container fluid className="mightyfit-page p-0">
      {!token ? 
      <Modal
      show={true}
      backdrop="static"
    >
      <Modal.Body className='font-barlow'>
        <div className='text-center fs-3'> 
          <p className='mt-2'>Please Log-in/Register<br /> To Access MightyFit.</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-dark" onClick={() => {navigate(`${urlParams}`);}}>
          Cancel
        </Button>
        <Button 
          variant="outline-dark" 
          onClick={() => navigate(`/my-account${urlParams}`)}>
          Log-in or Register
        </Button>
      </Modal.Footer>
    </Modal> : 
      <Modal
        show={isModalShow}
        onHide={() => setIsModalShow(false)}
        backdrop="static"
      >
        <Modal.Body className='font-barlow'>
          <div className='text-center fs-3'>
            {((!customerLoading && !customerLoaded) || (customerLoading && !customerLoaded)) ? 
            <p>Please wait while we checking your meals from the past two months.</p> : 
            <p className='mt-2'>Sorry, your MightyFit access is temporarily suspended due to no meal purchase in 2 months. 
                                Please buy our healthy and delicious food to reactivate.</p>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-dark" onClick={() => {navigate(`${urlParams}`);}}>
            Cancel
          </Button>
          <Button 
            variant="outline-dark" 
            onClick={() => { setIsModalShow(false); navigate(`/order${urlParams}`); }}
            disabled={((!customerLoading && !customerLoaded) || (customerLoading && !customerLoaded)) ? true : false}>          
            {((!customerLoading && !customerLoaded) || (customerLoading && !customerLoaded)) ? 
            <>Please wait... &nbsp;&nbsp;<Spinner animation="border" as="span" size="sm" /></> : 'Explore menu'}
          </Button>
        </Modal.Footer>
      </Modal>}
      <section className='fit-workout'>
        {/* banner-part */}
        <div className="fw-banner">
          <Image src={thumbMightyFitLeft} className='thumbMightyFitLeft' />
          <div className="fw-wrapper text-center">
            <Image src={thumbMightyFitLogo} className='thumbMightyFitLogo' />
            <h1 className='h1 text-black'>Workout Videos</h1>
            <p className='text-black'>Stay on track with FREE access to MightyFit workout videos, anywhere, anytime!</p>
          </div>
          <Image src={thumbMightyFitRight} className='thumbMightyFitRight' />
        </div>
        {/* filter-part */}
        <FilterPage />
        {/* trainers-part */}
        <div className="card-part">
          <WorkOutPage />
        </div>
      </section>
    </Container>
  )
}