import { Alert, Button, Col, Row, Spinner } from 'react-bootstrap';
import OrderObj from './orderObj';
import OrderAgainButton from './OrderAgainButton';
import RefundOrder from './RefundOrder';
import { useNavigate } from 'react-router-dom';
import { setCart } from '../cart/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../user/userSlice';
import CustomerObj from '../customer/customerObj';
import { useMemo, useState } from 'react';
import { selectProducts } from '../products/productsSlice';
import { selectCustomer } from '../customer/customerSlice';
import { setEditCouponLines, setExistingCartItems } from '../../pages/AutoshipPages/core/autoShipSlice';
import { selectIsMobileRoute } from '../mobile/mobileSlice';

interface Props {
  order: OrderObj;
  showActions?: boolean;
  autoshipOrder?: boolean;
}

export default function OrderDetails({ order, showActions = false, autoshipOrder = false }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const products = useSelector(selectProducts);
  const customerData = useSelector(selectCustomer);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const customer = useMemo(() => {
    return new CustomerObj(customerData);
  }, [customerData]);

  const handleOrderAgain = async () => {
    setIsUpdating(true);
    setErrorMsg('');
    try {
      const cartItems = order.existingItemsToCartItems(products, customer);
      await dispatch(setCart({ token: token, cart_items: cartItems }));
      await dispatch(setExistingCartItems(cartItems));
      await dispatch(setEditCouponLines(order.data.coupon_lines));
      navigate(`/autoship/menu/${order.data.id}${urlParams}`, {
        state: {
          isEditOrder: true,
          shippingId: order.data.shipping_lines[0].id,
          pickedForMyself: false,
          date: order?.getDeliveryDateTime()
        }
      });
    } catch (e: any) {
      console.log("Error while Editing Order: ", e);
      setErrorMsg('Error while Editing Order, Please try again letter.');
    } finally {
      setIsUpdating(false);
    }
  }

  return (
    <div className='order-details'>
      {errorMsg &&
        <Alert variant="danger" className='my-3'>{errorMsg}</Alert>}
      <div className='d-flex justify-content-between mb-3'>
        <div className='font-glegoo fs-23px fw-700'>Order Details</div>
        {showActions &&
          <div className='hstack gap-2'>
            {order.isRefundable() && <RefundOrder order={order} />}
            <OrderAgainButton order={order} />
          </div>
        }
        {autoshipOrder &&
          <div className='hstack gap-2'>
            <Button
              size="sm"
              variant="dark"
              className='mx-3 w-100'
              onClick={handleOrderAgain}
              disabled={isUpdating}
            >
              {isUpdating ?
                <>EDITING ORDER ...
                  <Spinner
                    animation="border"
                    as="span"
                    size="sm"
                  />
                </> : 'EDIT ORDER'}
            </Button>
          </div>}
      </div>
      <Row>
        <Col xs={12}>
          <Alert className='shadow-box bg-white text-black font-barlow fs-20px fw-700'>
            <Row>
              <Col xs={6} className='fs-5 fw-bold'>
                Product
              </Col>
              <Col xs={6} className='fs-5 fw-bold'>Total</Col>
            </Row>
          </Alert>
        </Col>
      </Row>
      {order.data.line_items.map((item: Record<string, any>, i: number) => (
        <Row className='px-3 py-1' key={i}>
          <Col xs={6} className='fs-5'>
            {item.name} <span className='fw-bold'>x {item.quantity}</span>
          </Col>
          <Col xs={6} className='fs-5 fw-bold font-barlow'>
            ${item.subtotal}
          </Col>
        </Row>
      ))}
      <Row className='my-2'>
        <Col xs={12}>
          <Alert className='shadow-box bg-white text-black font-barlow fs-20px fw-700'>
            <Row className='my-1'>
              <Col xs={6} className='fs-5 fw-bold'>
                Subtotal:
              </Col>
              <Col xs={6} className='fs-5 fw-bold'>
                {order.getSubtotal(true)}
              </Col>
            </Row>
            {order.data.discount_total && parseFloat(order.data.discount_total) > 0 &&
              <Row className='my-2'>
                <Col xs={6} className='fs-5 fw-bold'>
                  Discount:
                </Col>
                <Col xs={6} className='fs-5 fw-bold'>
                  ${order.data.discount_total}
                </Col>
              </Row>
            }
            <Row className='my-2'>
              <Col xs={6} className='fs-5 fw-bold'>
                Shipping:
              </Col>
              {order.data.shipping_lines.length > 0 &&
                <Col xs={6}>
                  <span className='fs-5 fw-bold'>${order.data.shipping_lines[0].total}</span><br />
                  via {order.data.shipping_lines[0].method_title}
                </Col>
              }
            </Row>
            <Row className='my-2'>
              <Col xs={6} className='fs-5 fw-bold'>Tax:</Col>
              <Col xs={6} className='fs-5 fw-bold'>${order.data.total_tax}</Col>
            </Row>
            <Row className='my-2'>
              <Col xs={6} className='fs-5 fw-bold'>Payment Method:</Col>
              <Col xs={6} className='fs-5'>
                {order.data.payment_method_title}
              </Col>
            </Row>
            <Row className='my-2'>
              <Col xs={6} className='fs-5 fw-bold'>Total:</Col>
              <Col xs={6} className='fs-5 fw-bold'>${order.data.total}</Col>
            </Row>
          </Alert>
        </Col>
      </Row>
    </div>
  );
}