import { Form, Formik, FormikErrors } from 'formik';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectGeneralOptions, selectIsMobileRoute, selectSavedCardData, setSavedCardData } from '../mobile/mobileSlice';
import creditCardType from 'credit-card-type';
import { selectOrderInProgress, setOrderInProgress } from '../cart/cartSlice';
import AuthNetAPI from '../../API/authNetAPI';
import { Alert, Button, Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AuthSavedCards from '../../components/checkout-form/AuthSavedCards';
import CreditCardDetails from '../../components/checkout-form/CreditCardDetails';
import CustomerObj from './customerObj';
import { selectCustomer } from './customerSlice';
import { selectToken } from '../user/userSlice';
import MyAccountNav from '../../components/MyAccountNav';

interface PaymentFormValues {
    cardNumber: string,
    cardExpiry: string,
    cardCvc: string,
    save_payment: boolean,
}

export default function AddEditPaymentMethod() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const token = useSelector(selectToken);
    const getSavedCardData = useSelector(selectSavedCardData);
    const orderInProgress = useSelector(selectOrderInProgress);
    const AppURL = useSelector(selectIsMobileRoute);
    const urlParams = AppURL ? `/?${AppURL}` : '';
    const [useNewPayment, setUseNewPayment] = useState(false);
    const [showSavedCards, setShowSavedCards] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const custData = useSelector(selectCustomer);
    const customer = useMemo(() => {
        return new CustomerObj(custData);
    }, [custData]);
    const getGeneralOptions = useSelector(selectGeneralOptions);
    const paymentOption = getGeneralOptions?.payment_option;
    const billing_info = Object.keys(customer.data).length > 0 ? customer.data.billing : {};
    const creditCardProcessor = paymentOption ? paymentOption : process.env.REACT_APP_CREDIT_CARD_PROCESSOR;
    const billing = {
        first_name: !!billing_info.first_name && billing_info.first_name.trim(),
        last_name: !!billing_info.last_name && billing_info.last_name.trim(),
        address_1: !!billing_info.address_1 && billing_info.address_1.trim(),
        city: !!billing_info.city && billing_info.city.trim(),
        state: !!billing_info.state && billing_info.state.trim(),
        postcode: !!billing_info.postcode && billing_info.postcode.trim(),
        country: 'US',
        phone: !!billing_info.phone && billing_info.phone.trim(),
        email: !!billing_info.email && billing_info.email.trim()
    };
    const anyNullOrEmpty = Object.values(billing).some(value => value === false);
    const isAutoship = location?.state && location?.state?.autoShip;

    useEffect(() => {
        if (errorMessage) {
            setTimeout(() => {
                setErrorMessage('');
            }, 5000)
        }
    }, [errorMessage])

    useEffect(() => {
        const savedCardData = getSavedCardData?.user_cim !== "No CIM Data Available" && getSavedCardData?.status === 200;
        if (token && creditCardProcessor === 'authorize.net' && savedCardData) {
            setShowSavedCards(true);
            setUseNewPayment(false);
        } else {
            setShowSavedCards(false);
            setUseNewPayment(true);
        }
    }, [token, getSavedCardData, creditCardProcessor]);

    const initialValues = {
        cardNumber: '',
        cardExpiry: '',
        cardCvc: '',
        save_payment: false,
    };

    const validateForm = (values: PaymentFormValues) => {
        let errors: FormikErrors<PaymentFormValues> = {};
        const setShowCard = showSavedCards ? useNewPayment ? true : false : true;

        if (creditCardProcessor === 'stripe') {
            if (!values.cardNumber || values.cardNumber === 'empty') {
                errors.cardNumber = 'Required';
            } else if (values.cardNumber !== 'complete') {
                errors.cardNumber = 'Your card number is incomplete';
            }
            if (!values.cardExpiry || values.cardExpiry === 'empty') {
                errors.cardExpiry = 'Required';
            } else if (values.cardExpiry !== 'complete') {
                errors.cardExpiry = "Your card's expiration date is incomplete";
            }
            if (!values.cardCvc || values.cardCvc === 'empty') {
                errors.cardCvc = 'Required';
            } else if (values.cardCvc !== 'complete') {
                errors.cardCvc = "Your card's security code is incomplete";
            }
        }

        if (setShowCard && creditCardProcessor === 'authorize.net') {
            if (!values.cardNumber) {
                errors.cardNumber = 'Required';
            } else if (values.cardNumber.replace(/\D/g, '').length < 13) {
                errors.cardNumber = 'Incomplete';
            }

            if (!values.cardExpiry) {
                errors.cardExpiry = 'Required';
            } else if (values.cardExpiry.replace(/\D/g, '').length < 4) {
                errors.cardExpiry = "Incomplete";
            } else {
                let month = parseInt(values.cardExpiry.substring(0, 2));
                if (month < 1 || month > 12) {
                    errors.cardExpiry = "Invalid";
                }
            }

            if (!values.cardCvc) {
                errors.cardCvc = 'Required';
            } else if (values.cardCvc.replace(/\D/g, '').length < 3) {
                errors.cardCvc = "Incomplete";
            }
        }

        return errors;
    }

    const handleAuthSavePayment = async (values: PaymentFormValues) => {
        try {
            setErrorMessage('');
            dispatch(setOrderInProgress(true));
            const eMail = customer.data.email ? customer.data.email : '';
            const keysExist = Object.keys(getSavedCardData).length > 0;
            const isCIMNotAvailable = getSavedCardData?.user_cim === "No CIM Data Available" && getSavedCardData?.status === 200;
            const ValidationMode = 'testMode';
            const expiryDateParts = values.cardExpiry.split('/');
            const formattedExpiryDate = `20${expiryDateParts[1]}-${expiryDateParts[0]}`;

            if (isCIMNotAvailable || !keysExist) {
                await AuthNetAPI.createProfile(
                    token,
                    formattedExpiryDate.replace(/\s/g, ''),
                    values.cardNumber.replace(/\s/g, ''),
                    ValidationMode
                ).then(async (result) => {
                    if (result.customerProfileId && result.customerPaymentProfileIdList && result.customerPaymentProfileIdList.length > 0) {
                        const customerProfileId = result.customerProfileId;
                        const customerPaymentProfileId = result.customerPaymentProfileIdList[0];
                        const lastFourDigits = values.cardNumber.slice(-4);
                        const accountType = await handleCardType(values.cardNumber);

                        const CIMKey = process.env.REACT_APP_AUTH_CIM_KEY || '';
                        const userCIMData = {
                            email: eMail,
                            mmAuthCardProfileId: customerPaymentProfileId,
                            mmAuthCardType: accountType,
                            mmAuthCardExperiation: formattedExpiryDate.replace(/\s/g, ''),
                            mmAuthCardLastFour: lastFourDigits,
                            mmAuthCustomerId: customerProfileId,
                            cimKey: CIMKey,
                        };

                        await AuthNetAPI.updateUserCIM(userCIMData).then((updateResponse) => {
                            dispatch(setSavedCardData(updateResponse));
                        }).catch((error) => {
                            console.error("Error updating user CIM data:", error);
                        });

                        await AuthNetAPI.creatCustPayProfile(
                            billing,
                            customerPaymentProfileId,
                            formattedExpiryDate.replace(/\s/g, ''),
                            values.cardNumber.replace(/\s/g, ''),
                            ValidationMode,
                        ).catch((error) => {
                            console.error("Error creating customer Payment profile:", error);
                        });
                    } else {
                        setErrorMessage('Error while saving card details, please try again letter.');
                        console.error("Unexpected response structure:", result);
                    }

                    if (result.messages.resultCode === "Error") {
                        setErrorMessage('Error while saving card details, please try again letter.');
                    }
                }).catch((error) => {
                    setErrorMessage('Error while saving card details, please try again letter.');
                    console.error("Error creating customer profile:", error);
                });

            } else if (useNewPayment) {
                await AuthNetAPI.updateCustPayProfile(
                    billing,
                    getSavedCardData?.user_cim?.authorize_customer_id,
                    getSavedCardData?.user_cim?.authorize_card_profile_id,
                    values.cardNumber.replace(/\s/g, ''),
                    formattedExpiryDate.replace(/\s/g, ''),
                    ValidationMode,
                ).then(async (result) => {
                    if (result.messages.resultCode === "Ok") {
                        await AuthNetAPI.getProfile(getSavedCardData.user_cim.authorize_customer_id).then(async (resResult) => {
                            const CIMKey = process.env.REACT_APP_AUTH_CIM_KEY || '';
                            const lastFourDigits = values.cardNumber.slice(-4);
                            const expiryDateParts = values.cardExpiry.split('/');
                            const formattedExpiryDate = `20${expiryDateParts[1]}-${expiryDateParts[0]}`;
                            const CardType = resResult.profile.paymentProfiles[0].payment.creditCard.cardType;

                            const userCIMData = {
                                email: resResult.profile.email,
                                mmAuthCardProfileId: resResult.profile.paymentProfiles[0].customerPaymentProfileId,
                                mmAuthCardType: CardType,
                                mmAuthCardExperiation: formattedExpiryDate.replace(/\s/g, ''),
                                mmAuthCardLastFour: lastFourDigits,
                                mmAuthCustomerId: resResult.profile.customerProfileId,
                                cimKey: CIMKey,
                            };

                            await AuthNetAPI.updateProfile(
                                resResult.profile.merchantCustomerId,
                                resResult.profile.description,
                                resResult.profile.email,
                                resResult.profile.customerProfileId
                            ).then(async () => {
                                await AuthNetAPI.updateUserCIM(userCIMData).then((updateCIMResponse) => {
                                    dispatch(setSavedCardData(updateCIMResponse));
                                }).catch((error) => {
                                    console.error("Error updating user CIM data:", error);
                                });
                            }).catch((error) => {
                                console.error("Error updating customer profile:", error);
                            });
                        }).catch((error) => {
                            console.error("Error getting customer profile:", error);
                        });
                    }
                    if (result.messages.resultCode === "Error") {
                        const errorCode = result?.messages?.message[0]?.code;
                        const errorText = result?.messages?.message[0]?.text;
                        if (errorCode === 'E00027' && errorText.includes('The credit card number is invalid')) {
                            setErrorMessage('The credit card number is invalid. Please check and try again.');
                        } else if (errorCode === 'E00027' && errorText.includes('The credit card has expired')) {
                            setErrorMessage('The credit card has expired. Please use a different card.');
                        } else {
                            setErrorMessage('Error while saving card details, please try again letter.');
                        }
                    }
                }).catch((error) => {
                    console.error("Error update customer pay profile:", error);
                });
            }
            dispatch(setOrderInProgress(false));
        } catch (error: any) {
            console.log(error, "error");
            dispatch(setOrderInProgress(false));
        } finally {
            if (isAutoship) {
                navigate(`/my-account/payment-method${urlParams}`);
            }
        }
    };

    const handleCardType = (number: any) => {
        const types = creditCardType(number);
        if (types.length > 0) {
            return types[0].niceType;
        } else {
            return 'Unknown';
        }
    }

    return (
        <Container fluid="lg" className='address-page'>
            <Row>
                <Col sm={12} md={3}>
                    <MyAccountNav />
                </Col>
                <Col sm={12} md={9} className="my-5">
                    <Card className="billing-address">
                        <Card.Header>
                            <div className="d-flex justify-content-between">
                                <span className="fs-4">Edit Payment Method</span>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Formik
                                initialValues={initialValues}
                                validate={validateForm}
                                onSubmit={(values) => {
                                    handleAuthSavePayment(values);
                                }}
                            >
                                {({
                                    values
                                }) => (
                                    <Form className='checkout-form'>
                                        {anyNullOrEmpty ?
                                            <Alert variant="danger" className='my-3 w-auto'>
                                                To continue, ensure all billing details are filled out completely.&nbsp;
                                                <Link to={`/my-account/address${urlParams}`}>Click here to fill billing details.</Link>
                                            </Alert> :
                                            <>
                                                {showSavedCards &&
                                                    <AuthSavedCards
                                                        useNewPayment={useNewPayment}
                                                        setUseNewPayment={setUseNewPayment} />}
                                                {!showSavedCards &&
                                                    <CreditCardDetails />}
                                                {errorMessage &&
                                                    <div className='d-flex justify-content-center'>
                                                        <Alert variant="danger" className='my-3 w-50 d-flex justify-content-center'>
                                                            {errorMessage}
                                                        </Alert>
                                                    </div>}
                                                {useNewPayment &&
                                                    <div className='mt-2 d-flex justify-content-end'>
                                                        <Button
                                                            variant='dark'
                                                            className='my-3'
                                                            size="lg"
                                                            disabled={orderInProgress}
                                                            type="submit">
                                                            {orderInProgress ?
                                                                <>
                                                                    Saving Card details ...&nbsp;&nbsp;
                                                                    <Spinner
                                                                        animation="border"
                                                                        as="span"
                                                                        size="sm"
                                                                    />
                                                                </> : 'Save Card details'}
                                                        </Button>
                                                    </div>}
                                            </>}
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
