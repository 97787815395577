import { useMemo, useState } from 'react';
import { Button, Image, Modal, Spinner} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { IMealPlanItem } from "./interfaces";
import MealPlanObj from './mealPlanObj';
import ProductObj from '../products/productObj';
import { useDispatch, useSelector } from 'react-redux';
import { selectProducts } from '../products/productsSlice';
import { selectCustomer } from '../customer/customerSlice';
import CustomerObj from '../customer/customerObj';
import PlusMinusInput from '../../components/PlusMinusInput';
import MealPlansAPI from '../../API/mealPlansAPI';
import { selectToken } from '../user/userSlice';
import { updateMealPlan } from './mealPlansSlice';
import { Link, useNavigate } from 'react-router-dom';
import { selectIsMobileRoute } from '../mobile/mobileSlice';

interface Props {
  mealPlan: MealPlanObj;
  item: IMealPlanItem;
}

export default function MealPlanItemRow({ mealPlan, item }: Props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [busy, setBusy] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const token = useSelector(selectToken);
  const products = useSelector(selectProducts);
  const product = ProductObj.getById(products, item.productId);
  const custData = useSelector(selectCustomer);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const customer = useMemo(() => {
    return new CustomerObj(custData);
  }, [custData]);


  const handleDelete = () => {
    setBusy(true);
    let mpCopy = new MealPlanObj(JSON.parse(JSON.stringify(mealPlan.data)));
    mpCopy.removeItem(item.productId);

    MealPlansAPI.updateMealPlan(token, mpCopy.data).then((mealPlan) => {
      dispatch(updateMealPlan(mealPlan));
    }).catch((e) => {
      console.error(e);
      setErrorMsg("An unexpected error occurred. Please try again later.");
    }).finally(() => {
      setBusy(false);
    });

  }

  const handleQtyChange = (qty: number) => {
    if (qty <= 0) return;

    setBusy(true);
    let mpCopy = new MealPlanObj(JSON.parse(JSON.stringify(mealPlan.data)));
    if (!mpCopy.setItemQuantity(item.productId, qty)) {
      setErrorMsg("An unexpected error occurred. Please try again later.");
      setBusy(false);
      return;
    };

    MealPlansAPI.updateMealPlan(token, mpCopy.data).then((mealPlan) => {
      dispatch(updateMealPlan(mealPlan));
    }).catch((e) => {
      console.error(e);
      setErrorMsg("An unexpected error occurred. Please try again later.");
    }).finally(() => {
      setBusy(false);
    });
  }

  return (
    <>
      {errorMsg &&
        <Modal show={Boolean(errorMsg)} onHide={() => setErrorMsg('')}>
          <Modal.Body className='p-4 text-center'>
            <p className='fs-4 mb-5'>{errorMsg}</p>
            <div className='d-flex flex-column'>
              <Button variant="dark" onClick={() => setErrorMsg('')}>
                OK
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      }
      <tr className="d-flex flex-wrap">
        <td className="px-3 d-block col-12 col-md-7">
          <div className='d-flex align-items-center'>
            <div className='pe-2'>
              <Button 
                variant="link"
                onClick={handleDelete} 
                disabled={busy}
                className='text-black'
              >
                <FontAwesomeIcon 
                  role='button'
                  icon={faCircleXmark} 
                  size={"lg" as SizeProp} 
                />
              </Button>
            </div>
            <div>
              {product ?
                <div className='d-flex'>
                  <Image 
                    src={product.data.images[0].src}
                    width="50"
                    height="50"
                    role='button'
                    onClick={() => navigate('/product/' + product.data.slug + urlParams)}
                    className='d-none d-sm-block'
                  />
                  <span className='ms-2 d-flex align-items-center'>
                    <Link 
                      to={'/product/' + product.data.slug + urlParams}
                      className='text-black'
                    >{product.data.name}</Link>
                  </span>
                </div>
              :
                <div className='mt-2'>
                  <Spinner animation="border" as="span" size="sm" title='Product Image Loading'/> 
                  &nbsp;&nbsp;&nbsp;{item.productName}</div>
              }
            </div>
          </div>
        </td>
        <td className="px-3 d-block col-4 col-md-2">
          {product ?
            <div>
              {customer.hasMembership() ?
                <div>
                  <div><del>${product.data.price}</del></div>
                  <div>{customer.getProductPrice(product, true)}</div>
                </div>
              :
                <div className='mt-2'>{customer.getProductPrice(product, true)}</div>
              }
            </div>
          :
            <div className='mt-2'>N/A</div>
          }
        </td>
        <td className="px-3 d-block col-8 col-md-3 d-flex justify-content-end align-items-center">
          <PlusMinusInput
            value={item.quantity}
            onChange={handleQtyChange}
            disabled={busy}
          />
        </td>
      </tr>
    </>
  )
}