import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebookF,
  faInstagram,
  faApple,
  faGooglePlay,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";
import Window from "../utils/Window";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsMobileRoute } from "../features/mobile/mobileSlice";

export default function MainBanner() {
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';

  // const handleClick = () => {
  //   (window as any)._klOnsite = (window as any)._klOnsite || [];
  //   (window as any)._klOnsite.push(['openForm', 'UEv5yt']);
  // };

  if (Window.isMobile() || Window.isTablet()) {
    return (
      <Container fluid className='main-banner bg-black p-2 position-relative zindex-20 desk51'>
        <div className='text-white font-glegoo fs-13px fw-700 text-center'>
          {/* New? Save $100 plus free delivery! <span role="button" className='border-bottom text-success' onClick={handleClick}>Redeem Here</span> */}
        </div>
      </Container>
    )
  }

  return (
    <Container fluid className='main-banner bg-black py-2 desk51'>
      <div className='d-flex'>
        <div className='w-50'>
          <div id='call-us'>
            <Link
              to={`/my-account/refer-a-friend${urlParams}`}
              className='text-white fs-16px' rel="noreferrer">
              <span
                className='text-white font-barlow border-bottom font-glegoo fs-16px  fw-700'
              >Send a Free Box Get $25
              </span>
            </Link>
          </div>
        </div>
        {/* <div className='text-white font-glegoo fs-16px  fw-700 text-center w-50'>
          New? Save $100 plus free delivery! <span role="button" className='border-bottom text-success' onClick={handleClick}>Redeem Here</span>
        </div> */}
        <div className='w-50 d-flex justify-content-end socialfeed'>
          <a
            href="https://www.facebook.com/EatMightyMeals/"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faFacebookF}
              size={"xl" as SizeProp}
              className='text-white mx-3 my-1'
            />
          </a>
          <a
            href="https://www.linkedin.com/company/eatmightymeals/"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faLinkedinIn}
              size={"xl" as SizeProp}
              className='text-white mx-3 my-1'
            />
          </a>
          <a
            href="https://www.instagram.com/eatmightymeals/"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              size={"xl" as SizeProp}
              className='text-white mx-3 my-1'
            />
          </a>
          <a
            href="https://apps.apple.com/us/app/mightymeals/id6451319843"
            rel="noreferrer"
            target="_blank"
            className="sb-apple"
          >
            <FontAwesomeIcon
              icon={faApple}
              size={"xl" as SizeProp}
              className='text-white mx-3 my-1'
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.mightymeals.app&hl=en_US&gl=US"
            rel="noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon
              icon={faGooglePlay}
              size={"xl" as SizeProp}
              className='text-white mx-3 my-1'
            />
          </a>
        </div>
      </div>
    </Container>
  );
}