import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Form, FormControl, Image, InputGroup, Row } from 'react-bootstrap';
import cardStars from '../../assets/images/Stars.svg';
import cardlogo from '../../assets/images/white-logo.svg';
import './MightyBucksGiftCardPage.scss';
import Loader from '../../components/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../user/userSlice';
import { selectCartItems, selectCartUpdateInProgress, selectOrderInProgress, setCartItem } from '../cart/cartSlice';
import CartObj from '../cart/cartObj';
import ToastError from '../../components/ToastError';
import { selectIsMobileRoute } from '../mobile/mobileSlice';
import { selectProducts } from './productsSlice';

export default function MightyBucksGiftCardPage() {
    const allProducts: Array<Record<string, any>> = useSelector(selectProducts);
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const location = useLocation();
    const navigate = useNavigate();
    const cartItems = useSelector(selectCartItems);
    const [errorMsg, setErrorMsg] = useState('');
    const [giftCards, setGiftCards] = useState<any>([]);
    const [mightyBucks, setMightyBucks] = useState<any>([]);
    const [selectedProductId, setSelectedProductId] = useState([]);
    const [giftPrice, setGiftPrice] = useState(0);
    const [isAdding, setIsAdding] = useState(false);
    const [isPurchasing, setIsPurchasing] = useState(false);
    const cartUpdateInProgress = useSelector(selectCartUpdateInProgress);
    const orderInProgress = useSelector(selectOrderInProgress);
    const AppURL = useSelector(selectIsMobileRoute);
    const QURLParams = AppURL ? `/?${AppURL}` : '/';
    const AndURLParams = AppURL ? `&${AppURL}` : '';
    const cart = useMemo(() => {
        return new CartObj(cartItems)
    }, [cartItems]);

    useEffect(() => {
        //MightyBucks GiftCard Add
        const mightyBucksGiftCard = allProducts.filter((product) => {
            return product.slug.startsWith("mighty-bucks-gift-card-for-");
        })
        if (mightyBucksGiftCard.length > 0) {
            setMightyBucks(mightyBucksGiftCard);
        }

        //GiftCard Add
        const giftCard = allProducts.filter((product) => {
            return product.slug.startsWith("gift-card");
        })
        if (giftCard.length > 0) {
            setGiftCards(giftCard);
        }
    }, [allProducts])

    const handlePriceChange = async (price: number, product: any) => {
        if (!cart.isEmpty()) {
            return 'Please clear your cart to purchase this item.';
        }
        if (Number(price) === 0) {
            return 'Please add a gift card for at least $1.';
        }
        await dispatch(setCartItem({
            token: token,
            cart_item: {
                product_id: product.id,
                product_qty: 1,
                product_price: price
            }
        }));
        return '';
    }

    const handlePurchase = async (e: any, giftCard: any) => {
        e.preventDefault();
        const searchParams = new URLSearchParams(location.search);
        const couponCode = searchParams.get('coupon-code');
        const error = await handlePriceChange(giftPrice, giftCard);
        if (error) {
            setErrorMsg(error)
            setTimeout(() => {
                setErrorMsg('')
            }, 5000)
        }
        else {
            if (await handlePriceChange(giftPrice, giftCard)) {
                e.target.value = "0";
            }
            if (couponCode) {
                navigate(`/checkout?coupon-code=${couponCode}${AndURLParams}`);
            }
            else {
                navigate(`/checkout${QURLParams}`);
            }
        }
    }

    const handleOnChange = (e: any) => {
        setGiftPrice(e.target.value);
    }

    const handleBucksGirftCards = async (price: any, CardId: number) => {
        if (!cart.isEmpty()) {
            setErrorMsg('Please clear your cart to purchase this item.');
            setTimeout(() => {
                setErrorMsg('');
            }, 5000);
        } else {
            setIsAdding(true);
            if (CardId) {
                await dispatch(setCartItem({
                    token: token,
                    cart_item: {
                        product_id: CardId,
                        product_qty: 1,
                        product_price: price
                    }
                }));
                navigate(`/checkout${QURLParams}`);
            }
            setIsAdding(false);
        }
    }

    const handleGiftCardPurchase = () => {
        if (!cart.isEmpty()) {
            setErrorMsg('Please clear your cart to purchase this item.');
            setTimeout(() => {
                setErrorMsg('');
            }, 5000);
        }
        else {
            setIsPurchasing(true)
        }
    }

    return (
        <Container className="mightyBucks-page">
            <div className='introducing-container'>
                <div className='introducing'>
                    <h1>Introducing <span>MightyBucks!</span></h1>
                    <h2>Purchase now and save big!</h2>
                    <p>This innovative currency from MightyMeals not only simplifies your meal ordering but also offers incredible savings—buy in bulk and save up to 10% off! With each MightyBuck equivalent to $1 USD, enjoying your favorite nutritious meals has never been more rewarding.</p>
                </div>
            </div>
            {(giftCards.length === 0 || mightyBucks.length === 0) ? <Loader /> :
                <div className='mb-cards row'>
                    {giftCards.map((giftCard: any, index: number) => (
                        <div className='mb-card-wrap mb-top col-xl-3 col-md-4 col-sm-6' key={index}>
                            <div className={` gift-card`}>
                                <div className='mb-bg-wrap'>
                                    <div className='mb-bg-image'>
                                        <Image
                                            src={cardStars}
                                        />
                                    </div>
                                    <div className='mb-top-wrap'>
                                        <div className='mb-left'>
                                            <div className='mb-bg-gradient'></div>
                                            <div className='mb-img-content'>
                                                <div className='mb-img-top'>
                                                    <div className='mb-logo'>
                                                        <Image
                                                            src={cardlogo}
                                                            alt="MightyMeals Logo"
                                                        />
                                                    </div>
                                                    <h6 className='mb-gift'>Gift Card</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-right'>
                                            <div className={`mb-img-wrap gift-img-wrap`}>
                                                <img src={giftCard.images[0].src} alt='meal' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {selectedProductId === giftCard.id && errorMsg &&
                                    <ToastError
                                        msg={errorMsg}
                                        onClose={() => setErrorMsg('')}
                                        position="middle-center"
                                    />
                                }
                                <div className='mb-line'></div>
                                <div className='mb-content-block '>
                                    <h5 className='mb-5'>Standard Gift Card</h5>
                                    {!isPurchasing &&
                                        <>
                                            <p className='mb-3'>Click below to enter the gift card value</p>
                                            <Button
                                                variant="success"
                                                size="sm"
                                                onClick={() => {
                                                    setSelectedProductId(giftCard.id);
                                                    handleGiftCardPurchase();
                                                }}>
                                                PURCHASE
                                            </Button>
                                        </>
                                    }
                                    {isPurchasing &&
                                        <Form className='py-3'>
                                            <InputGroup className='nyp-input mb-4 d-flex justify-content-center my-2'>
                                                <Row className='align-items-center  justify-content-center flex-nowrap'>
                                                    <Col className=' px-1 col1 font-glegoo h3 pt-1 fw-bold'>
                                                        $
                                                    </Col>
                                                    <Col className="px-1 col2">
                                                        <FormControl
                                                            type="text"
                                                            maxLength={4}
                                                            size="sm"
                                                            className="text-center rounded-1"
                                                            defaultValue={giftPrice}
                                                            disabled={orderInProgress || cartUpdateInProgress}
                                                            onChange={(e: any) => handleOnChange(e)}
                                                        />
                                                    </Col>
                                                    <Col className="px-1 col2">
                                                        <Button
                                                            type='submit'
                                                            variant="success"
                                                            className='fw-500 w-100 rounded-1'
                                                            onClick={(e: any) => {
                                                                setSelectedProductId(giftCard.id);
                                                                handlePurchase(e, giftCard);
                                                            }}>
                                                            PURCHASE
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </InputGroup>
                                        </Form>}
                                </div>
                            </div>
                        </div>
                    ))}
                    {mightyBucks.map((giftCard: any, index: number) => (
                        <div className={`mb-top col-xl-3 col-md-4 col-sm-6`} key={index}>
                            <div className='mb-bg-wrap'>
                                <div className='mb-bg-image'>
                                    <Image
                                        src={cardStars}
                                    />
                                </div>
                                <div className='mb-top-wrap'>
                                    <div className='mb-left'>
                                        <div className='mb-bg-gradient'></div>
                                        <div className='mb-img-content'>
                                            <div className='mb-img-top'>
                                                <div className='mb-logo'>
                                                    <Image
                                                        src={cardlogo}
                                                        alt="MightyMeals Logo"
                                                    />
                                                </div>
                                                <h6 className='mb-gift'>Gift Card</h6>
                                            </div>
                                            <div className='mb-img-bottom'>
                                                <div className='mb-price'>${giftCard?.regular_price}</div>
                                                <div className='mb-subtitle'>Mightybucks</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mb-right'>
                                        <div className={`mb-img-wrap ${giftCard.slug === 'gift-card' ? 'gift-img-wrap' : ''}`}>
                                            <img src={giftCard.images[0].src} alt='meal' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {selectedProductId === giftCard.id && errorMsg &&
                                <ToastError
                                    msg={errorMsg}
                                    onClose={() => setErrorMsg('')}
                                    position="middle-center"
                                />
                            }
                            <div className='mb-line'></div>
                            <div className='mb-content-block'>
                                <h5 className='font-barlow'>${giftCard?.regular_price} for ${giftCard?.price}</h5>
                                <h5 className='pb-4'>MightyBucks Gift Card</h5>
                                <p className='pb-0'>Buy <span className='font-barlow'>$</span>{giftCard?.regular_price} worth of MightyBucks for
                                    <span className='font-barlow'> $</span>{giftCard?.price}</p>
                                <p className='fw-bolder'>{giftCard.meta_data.map((metaItems: any, idx: number) => (
                                    metaItems.key === 'gift_card_discount_note' && <span key={idx} className='fs-20px fw-bold'> {metaItems.value} Discount</span>
                                ))}</p>
                                <Button
                                    variant="success"
                                    size="sm"
                                    disabled={orderInProgress || cartUpdateInProgress || isAdding}
                                    onClick={() => {
                                        setSelectedProductId(giftCard.id);
                                        handleBucksGirftCards(giftCard?.price, giftCard.id);
                                    }}>
                                    PURCHASE
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>}
        </Container>
    )
}