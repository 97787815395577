import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Spinner, Table } from 'react-bootstrap';
import { loadOrders, resetOrders, selectOrders, selectOrdersPagesLoaded, selectOrdersTotalPages } from './ordersSlice';
import OrderObj from './orderObj';
import ChangeDeliveryDate from './ChangeDeliveryDate';
import OrderAgainButton from './OrderAgainButton';
import RefundOrder from './RefundOrder';
import { selectToken } from '../user/userSlice';
import Loader from '../../components/Loader';
import { selectIsMobileRoute, setIsLoadMore } from '../mobile/mobileSlice';
import { useEffect, useState } from 'react';

export default function MyOrders() {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const pages_loaded: number = useSelector(selectOrdersPagesLoaded);
  const allOrders: Array<Record<string, any>> = useSelector(selectOrders);
  const orders = allOrders.filter((order, index, self) => (order.status !== "autoship" ||
    order.status !== "mmfailpayment" || order.status !== "mmcancelled") &&
    index === self.findIndex((o) => o.id === order.id));
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const total_pages: number = useSelector(selectOrdersTotalPages);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(resetOrders());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        await dispatch(loadOrders({ token }));
        await dispatch(setIsLoadMore(false));
        setIsLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleRefetch = () => {
    dispatch(loadOrders({ token }));
    navigate(`/my-account/orders${urlParams}`);
  }

  const loadMoreOrders = async () => {
    setIsLoading(true);
    dispatch(setIsLoadMore(true));
    if (token && (!pages_loaded || pages_loaded < total_pages)) {
      await dispatch(loadOrders({ token }));
      await dispatch(setIsLoadMore(false));
      setIsLoading(false);
    }
  }

  return (
    <Card body className='my-orders my-5 flex-fill'>
      {!pages_loaded ?
        <div className='text-center my-5 h-100'>
          <Loader />
        </div> : <>
          <Table
            striped
            responsive
            className='table-borderless table-sm'
          >
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Status</th>
                <th>Delivery Date</th>
                <th>Total</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((orderData, index: number) => {
                const order = new OrderObj(orderData);
                return (
                  <tr key={index} className='position-relative'>
                    <td>
                      <Link
                        to={'/my-account/orders/' + order.data.id + urlParams}
                        className='text-black'
                      >#{order.data.id}</Link>
                    </td>
                    <td>{order.getDate()}</td>
                    <td>{order.data.status}</td>
                    <td>{order.getDeliveryDate()}</td>
                    <td>
                      <span className='fw-bold'>${order.data.total ? order.data.total : order.data.order_total}</span><br />
                      for {order.getTotalItems()} items
                    </td>
                    <td>
                      <div className='vstack gap-1'>
                        <OrderAgainButton order={order} />
                        {order.isModifiable() && <ChangeDeliveryDate order={order} />}
                        {order.isRefundable() && <RefundOrder order={order} handleRefetch={handleRefetch} />}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <div className='d-flex justify-content-center align-items-center'>
            {pages_loaded < total_pages &&
              <Button
                onClick={loadMoreOrders}
                disabled={isLoading}
                variant='success'
                className='text-white'
              >
                {isLoading ?
                  <>
                    Loading ... &nbsp;
                    <Spinner
                      animation="border"
                      as="span"
                      size="sm"
                    />
                  </>
                  : 'Load more'}
              </Button>
            }
          </div>
        </>}
    </Card>
  );
}