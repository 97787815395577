import { Link, useLocation } from 'react-router-dom';
import { Button, Dropdown, ProgressBar, Spinner } from 'react-bootstrap';
import WelcomeCustomer from '../features/customer/WelcomeCustomer';
import './my-account-nav.scss';
import { useEffect, useState } from 'react';
import { selectCustomerDetails, selectCustomerDetailsIsLoaded, selectCustomerDetailsIsLoading } from '../features/customer/customerDetailSlice';
import { useSelector } from 'react-redux';
import { selectGeneralOptions, selectIsMobileRoute } from '../features/mobile/mobileSlice';

export default function MyAccountNav() {
  const location = useLocation();
  const [progress, setProgress] = useState(0);
  const customerData = useSelector(selectCustomerDetails);
  const customerLoading = useSelector(selectCustomerDetailsIsLoading);
  const customerLoaded = useSelector(selectCustomerDetailsIsLoaded);
  const getGeneralOptions = useSelector(selectGeneralOptions);
  const AppURL = useSelector(selectIsMobileRoute);
  const urlParams = AppURL ? `/?${AppURL}` : '';
  const customerDetails = customerData?.user_data;
  const [disableAutoship, setDisableAutoship] = useState('');
  const paymentOption = getGeneralOptions?.payment_option;
  const creditCardProcessor = paymentOption ? paymentOption : process.env.REACT_APP_CREDIT_CARD_PROCESSOR;

  const openFreshDeskWindow = () => {
    window.open("https://mightymeals.freshdesk.com/support/tickets/new", '_blank');
  }

  useEffect(() => {
    async function fetchData() {
      try {
        if (getGeneralOptions) {
          setDisableAutoship(getGeneralOptions?.disable_autoship_btn);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGeneralOptions])

  useEffect(() => {
    if (customerDetails) {
      const totalFields = Object.keys(customerDetails).filter(key => !key.includes('address_two') && !key.includes('number_of_children_in_household') && !key.includes('imageUrl')).length;
      const numCompletedFields = Object.values(customerDetails).filter((val, index) => val !== "" && val !== null && !Object.keys(customerDetails)[index].includes('address_two') && !Object.keys(customerDetails)[index].includes('number_of_children_in_household') && !Object.keys(customerDetails)[index].includes('imageUrl')).length;
      const ProgressBar = Math.round((numCompletedFields / totalFields) * 100);
      setProgress(ProgressBar);
    }
  }, [customerDetails])

  return (
    <div className='my-account-nav my-md-5'>
      <WelcomeCustomer />
      <div className="d-grid">
        <Button
          as={Link as any}
          to={`/order${urlParams}`}
          variant="white"
          size="sm"
          className={`text-white border-bottom bg-success border-1 text-start`}
        >ORDER NOW</Button>
        <Button
          as={Link as any}
          to={`/my-account${urlParams}`}
          variant="white"
          size="lg"
          className={`border-bottom border-1 text-start ${location.pathname === '/my-account' ? 'text-white bg-dark' : ""}`}
        >Dashboard</Button>
        <Button
          as={Link as any}
          to={`/my-account/orders${urlParams}`}
          variant="white"
          size="lg"
          className={`border-bottom border-1 text-start ${location.pathname === '/my-account/orders' ? 'text-white bg-dark' : ""}`}
        >My Orders</Button>
        {!disableAutoship &&
          <Button
            as={Link as any}
            to={`/autoship/orders${urlParams}`}
            variant="white"
            size="lg"
            className={`border-bottom border-1 text-start ${location.pathname === '/autoship/orders' ? 'text-white bg-dark' : ""}`}
          >Order Planner</Button>}
        <Button
          as={Link as any}
          to={`/my-account/my-meal-plans${urlParams}`}
          variant="white"
          size="lg"
          className={`border-bottom border-1 text-start ${location.pathname === '/my-account/my-meal-plans' ? 'text-white bg-dark' : ""}`}
        >My Favorite Meals</Button>
        <Button
          as={Link as any}
          to={`/my-account/refer-a-friend${urlParams}`}
          variant="white"
          size="lg"
          className={`border-bottom border-1 text-start ${location.pathname === '/my-account/refer-a-friend' ? 'text-white bg-dark' : ""}`}
        >Send A Free Box</Button>
        <Dropdown align="end">
          <Dropdown.Toggle
            id="account-dropdown"
            size="lg"
            variant="white"
            className={`w-100 text-start text-black border-bottom border-1 ${location.pathname === '/my-account/edit-account' ? 'text-white bg-dark' : ""}`}
          >
            Account
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100 fs-5 font-barlow">
            <Dropdown.Item as={Link} to={`/my-account/gift-cards${urlParams}`}>
              Gift Cards &amp; Store Credits
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/my-account/mightypoints${urlParams}`}>
              Mighty Points
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/my-account/my-membership-details${urlParams}`}>
              My Membership
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/my-account/edit-account${urlParams}`} disabled={(!customerLoading && customerLoaded && customerData) ? false : true}>
              Edit Account {(!customerLoading && customerLoaded && customerData) ? '' : <Spinner animation="border" as="span" size="sm" />}
              <div className='pt-2'>
                {progress === 0
                  ? <ProgressBar now={0} label={`${0}%`} className='h-20px px-2' />
                  : <ProgressBar now={progress} label={`${progress}%`} className='h-20px' />}
              </div>
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/my-account/address${urlParams}`}>
              Edit Address
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={`/my-account/change-password${urlParams}`}>
              Change Password
            </Dropdown.Item>
            {creditCardProcessor === 'authorize.net' &&
              <Dropdown.Item as={Link} to={`/my-account/payment-method${urlParams}`}>
                Edit Payment Method
              </Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown align="end">
          <Dropdown.Toggle
            id="support-dropdown"
            size="lg"
            variant="white"
            className="w-100 text-start text-black border-bottom border-1"
          >
            Support &amp; Help
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100 fs-5 text-black font-barlow">
            <Dropdown.Item
              onClick={openFreshDeskWindow}
            >
              Submit Support Ticket
            </Dropdown.Item>
            <Dropdown.Item
              onClick={openFreshDeskWindow}
            >
              Refund Requests
            </Dropdown.Item>
            <Dropdown.Item
              onClick={openFreshDeskWindow}
            >
              Request Delivery Date Change
            </Dropdown.Item>
            <Dropdown.Item
              onClick={openFreshDeskWindow}
            >
              Verify Military Discount
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  )
}